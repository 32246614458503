import React, {useEffect, useState} from 'react';
import GFXButton from '../Button';
import Loading from '../Loading';
import Modal from '../Modal';
import Icon from '../Icons/Icon';
import './LoadingModal.scss';

interface RequiredProps {
  loading: boolean;
  showSuccess: boolean;
}

interface OptionalProps {
  onClose?: () => void;
  redirectUrl?: string;
  title?: string;
  description?: string | React.ReactNode;
  tips?: React.ReactNode[];
}

type LoadingModalProps = OptionalProps & RequiredProps;

const TIPS_ROTATION_INTERVAL_MS = 3500;

const LoadingModalWithTips: React.FC<LoadingModalProps> = ({
  loading,
  showSuccess,
  onClose,
  redirectUrl,
  title,
  description,
  tips = [],
}) => {
  const [currentTipIndex, setCurrentTipIndex] = useState(-1);

  useEffect(() => {
    if (tips.length > 0) {
      const interval = setInterval(() => {
        setCurrentTipIndex((prevIndex) => {
          if (prevIndex >= tips.length - 1) {
            return 0;
          } else {
            return (prevIndex + 1) % tips.length;
          }
        });
      }, TIPS_ROTATION_INTERVAL_MS);
      return () => clearInterval(interval);
    }
  }, [loading, tips]);

  const handleOnClose = () => {
    if (loading) {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  title = title || 'We are creating something amazing';

  return (
    <div>
      <Modal.SimpleModal
        className="modal-middle-page modal-styled"
        modalTile=""
        show={loading || showSuccess}
        onCloseModal={handleOnClose}
        isSaveEnabled={false}
        addCloseButton={!!onClose}
      >
        <div id="LoadingModalWithTips">
          <span className="modal-loading-title">{title}</span>
          {loading && <Loading spinnerOnly />}
          {showSuccess && <Icon name="checkIcon" width={100} height={100} />}
          <span className="modal-loading-description">
            {currentTipIndex < 0 ? description : tips[currentTipIndex]}
          </span>
          <GFXButton
            href={redirectUrl}
            onClick={handleButtonClick}
            disabled={loading}
          >
            Let&apos;s design
          </GFXButton>
        </div>
      </Modal.SimpleModal>
    </div>
  );
};

export default LoadingModalWithTips;
