import * as Contracts from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';

import fontsApi from '../../api/fonts';
import {RootState} from '../../app/store';

const Fonts = new schema.Entity('fonts');
const fontsSchema = {fonts: [Fonts]};

export interface FontsState {
  entities: {
    [id: number]: Contracts.FontsDBModel;
  };
  pages: {[page: number]: number[]};
  pageFetchStatus: {[offset: number]: string};
  fetchStatus: Contracts.ELoadingStates;
  total: number;
}

const initialState: FontsState = {
  entities: {},
  pages: {},
  pageFetchStatus: {},
  fetchStatus: Contracts.ELoadingStates.IDLE,
  total: 0,
};

export const getFontsAsync = createAsyncThunk(
  'fonts/fetch',
  async (parameters: Contracts.FontsRequest) => {
    const response = await fontsApi.getFonts(parameters);

    const normalizedData = normalize({fonts: response?.fonts}, fontsSchema);

    return {
      entities: normalizedData.entities.fonts,
      pages: normalizedData.result.fonts,
      totalFonts: response?.totalFonts,
      ...parameters,
    };
  },
);

export const loadFonts = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    resetFonts: (state) => {
      state.entities = {};
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;
    },
    resetFontsPages: (state) => {
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFontsAsync.pending, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.LOADING;
      })
      .addCase(getFontsAsync.fulfilled, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.LOADED;
        state.entities = {...state.entities, ...action.payload.entities};
        state.pages[action.meta.arg.page] = action.payload.pages;
        state.total = action.payload.totalFonts || 0;
      })
      .addCase(getFontsAsync.rejected, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.FAILED;
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.fonts.entities[id];
};

export const getFontsByPage = (state: RootState, page: number) => {
  const ids = state.fonts.pages[page] || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, id));
  } else {
    return [];
  }
};

export const selectTotalFonts = (state: RootState) => {
  return state.fonts.total || 0;
};

export const fetchStatusByPage = (state: RootState, page: number) => {
  return state.fonts.pageFetchStatus[page] || Contracts.ELoadingStates.IDLE;
};

export const getDetailFetchStatus = (state: RootState) => {
  return state.fonts.fetchStatus || Contracts.ELoadingStates.IDLE;
};

export const {resetFonts, resetFontsPages} = loadFonts.actions;

export default loadFonts.reducer;
