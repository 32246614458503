import * as Contracts from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';

import templateApi from '../../api/templateApi';
import {RootState} from '../../app/store';

const DesignTemplate = new schema.Entity('designTemplates');
const designTemplateSchema = {designTemplates: [DesignTemplate]};

export interface DesignTemplateState {
  entities: {
    [id: number]: Contracts.ISlotTemplateCanvas;
  };
  pages: {[page: number]: number[]};
  pageFetchStatus: {[offset: number]: Contracts.ELoadingStates};
  detailFetchStatus: Contracts.ELoadingStates;
  total: number;
}

const initialState: DesignTemplateState = {
  entities: {},
  pages: {},
  pageFetchStatus: {},
  detailFetchStatus: Contracts.ELoadingStates.IDLE,
  total: 0,
};

export const getDesignTemplatesAsync = createAsyncThunk(
  'designTemplates/fetch',
  async (parameters: Contracts.IDesignTemplateRequest) => {
    const response = await templateApi.getDesignTemplatesEntities(parameters);

    const normalizedData = normalize(
      {designTemplates: response?.designTemplates},
      designTemplateSchema,
    );

    // The value we return becomes the `fulfilled` action payload
    return {
      entities: normalizedData.entities.designTemplates,
      pages: normalizedData.result.designTemplates,
      totalDesignTemplates: response?.totalDesignTemplates,
      ...parameters,
    };
  },
);

export const getDesignTemplateDetailAsync = createAsyncThunk(
  'designTemplates/fetch_detail',
  async (parameters: Contracts.ISlotTemplateDetailRequest) => {
    const response = await templateApi.getSlotTemplateDetail(parameters);

    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const loadDesignTemplates = createSlice({
  name: 'designTemplates',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDesignTemplates: (state) => {
      state.entities = {};
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;
    },
    resetDesignTemplatesPages: (state) => {
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;

      return state;
    },
    resetDetailFetchStatus: (state) => {
      state.detailFetchStatus = Contracts.ELoadingStates.IDLE;
    },
    addDesignTemplateToList: (state, action) => {
      state.entities[action.payload.id] = action.payload;
      state.pages[1] = state.pages[1]
        ? [action.payload.id, ...state.pages[1]]
        : [action.payload.id];
      return state;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDesignTemplatesAsync.pending, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.LOADING;
      })
      .addCase(getDesignTemplatesAsync.fulfilled, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.LOADED;
        state.entities = {...state.entities, ...action.payload.entities};
        state.pages[action.meta.arg.page] = action.payload.pages;
        state.total = action.payload.totalDesignTemplates || 0;
      })
      .addCase(getDesignTemplatesAsync.rejected, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] =
          Contracts.ELoadingStates.FAILED;
      })
      .addCase(getDesignTemplateDetailAsync.pending, (state) => {
        state.detailFetchStatus = Contracts.ELoadingStates.LOADING;
      })
      .addCase(getDesignTemplateDetailAsync.fulfilled, (state, action) => {
        state.detailFetchStatus = Contracts.ELoadingStates.LOADED;
        state.entities[action.meta.arg.templateId] = {
          ...state.entities[action.meta.arg.templateId],
          ...action.payload!,
        };
      })
      .addCase(getDesignTemplateDetailAsync.rejected, (state) => {
        state.detailFetchStatus = Contracts.ELoadingStates.FAILED;
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.designTemplates.entities[id];
};

export const getDesignTemplatesByPage = (state: RootState, page: number) => {
  const ids = state.designTemplates.pages[page] || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, id));
  } else {
    return [];
  }
};

export const selectTotalDesignTemplates = (state: RootState) => {
  return state.designTemplates.total || 0;
};

export const fetchStatusByPage = (state: RootState, page: number) => {
  return (
    state.designTemplates.pageFetchStatus[page] || Contracts.ELoadingStates.IDLE
  );
};

export const getDetailFetchStatus = (state: RootState) => {
  return (
    state.designTemplates.detailFetchStatus || Contracts.ELoadingStates.IDLE
  );
};

export const {
  resetDesignTemplates,
  resetDesignTemplatesPages,
  resetDetailFetchStatus,
  addDesignTemplateToList,
} = loadDesignTemplates.actions;

export default loadDesignTemplates.reducer;
