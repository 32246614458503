import React from 'react';

import './Banner.scss';

interface RequiredProps {
  show: boolean;
  children: React.ReactNode;
}

interface OptionalProps {}

type BannerProps = RequiredProps & OptionalProps;

const Banner: React.FC<BannerProps> = ({show, children}) => {
  if (!show) return null;
  return <div id="Banner">{children}</div>;
};

export default Banner;
