import React from 'react';
import {Link} from 'react-router-dom';
import debounce from 'lodash.debounce';
import {InputGroup, Form} from 'react-bootstrap';
import {Search, ArrowClockwise} from 'react-bootstrap-icons';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import useQueryParams from '../../hooks/useQueryParams';
import {selectShop} from '../../features/shops/selectedShop';

import {
  getCollectionsAsync,
  selectCollections,
  selectCollectionsFetchStatus,
  selectTotalCollections,
  resetCollectionDetails,
} from '../../features/collections/loadCollectionsDetails';

import Icon from '../Icons/Icon';
import CollectionBreadcrumb from '../ImageDashboard/CollectionBreadcrumb';

import CollectionsPanelOption from './CollectionsPanelOption';
import ImagesByCollection from './ImagesByCollection';
import {CollectionSticker, ActionParams} from './types';
import Loading from '../Loading';
import SearchNotFound from '../SearchNotFound';

import {
  ELoadingStates,
  ICollectionsRequest,
  ICollectionResponse,
} from '@gfxco/contracts';

import './CollectionsPanel.scss';

type OptionalProps = {
  buttonText?: string;
  onSave?: (ids: CollectionSticker[]) => void;
  collectionStickers?: CollectionSticker[];
};

type RequiredProps = {};

type CollectionListProps = OptionalProps & RequiredProps;

const CollectionList: React.FC<CollectionListProps> = ({
  onSave,
  collectionStickers = [],
}) => {
  const shopSelected = useAppSelector(selectShop);
  const [queryParams, setQueryParams] = useQueryParams();

  const shopId = shopSelected?.id;
  const dispatch = useAppDispatch();

  const collections = useAppSelector(selectCollections);
  const collectionsFetchStatus = useAppSelector(selectCollectionsFetchStatus);
  const totalCollections = useAppSelector(selectTotalCollections);

  const [collectionsOffset, setCollectionsOffset] = React.useState(0);
  const collectionsLimit = 10;

  const [collectionSelected, setCollectionSelected] =
    React.useState<ActionParams>();

  const BREADCRUMB_DEFAULT = [
    {
      name: 'All collections',
      icon: <Icon name="homeFill" />,
      onClick: () => handleBreadcrumbClick('all'),
    },
  ];
  const [breadcrumbItems, setBreadcrumbItems] =
    React.useState<any[]>(BREADCRUMB_DEFAULT);

  const navigationRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = navigationRef.current;
    if (collectionsFetchStatus === ELoadingStates.LOADED && container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [collectionsFetchStatus]);

  React.useEffect(() => {
    dispatch(resetCollectionDetails());
  }, [queryParams.search]);

  React.useEffect(() => {
    const params: ICollectionsRequest = {
      shopId,
      offset: collectionsOffset,
      limit: collectionsLimit,
    };

    if (queryParams.search) params.name = queryParams.search;
    dispatch(getCollectionsAsync(params));
  }, [collectionsOffset]);

  React.useEffect(() => {
    if (collectionsFetchStatus !== ELoadingStates.IDLE) return;

    const params: ICollectionsRequest = {
      shopId,
      offset: collectionsOffset,
      limit: collectionsLimit,
    };

    if (queryParams.search) params.name = queryParams.search;
    dispatch(getCollectionsAsync(params));
  }, [shopId, collectionsFetchStatus]);

  const handleScroll = () => {
    const container = navigationRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (
      scrollTop + clientHeight + 1 < scrollHeight ||
      collectionsFetchStatus === ELoadingStates.LOADING ||
      collections?.length === +totalCollections
    ) {
      return;
    }

    const newOffset = collectionsOffset + collectionsLimit;
    setCollectionsOffset(newOffset);
  };

  const handleOnClick = (item: ICollectionResponse, isSub?: boolean) => {
    setCollectionSelected(item);

    if (isSub) {
      setQueryParams({...queryParams, subCollectionId: item.id});
    } else {
      setQueryParams({...queryParams, collectionId: item.id});
    }

    const type = isSub ? 'subCol' : 'col';
    setBreadcrumbItems([
      ...breadcrumbItems,
      {
        name: item?.name,
        icon: <Icon name="folder" fillColor={{default: '#4573bb'}} />,
        onClick: () => handleBreadcrumbClick(type, item),
      },
    ]);
  };

  const handleBreadcrumbClick = (
    type: 'all' | 'col' | 'subCol',
    item?: ICollectionResponse,
  ) => {
    if (type === 'all') {
      setCollectionSelected(undefined);
      setBreadcrumbItems(BREADCRUMB_DEFAULT);
      setQueryParams({
        ...queryParams,
      });
      return;
    }

    if (type === 'col') {
      setBreadcrumbItems([
        ...BREADCRUMB_DEFAULT,
        {
          name: item?.name,
          icon: <Icon name="folder" fillColor={{default: '#4573bb'}} />,
          onClick: () => handleBreadcrumbClick(type, item),
        },
      ]);
      setQueryParams({...queryParams, collectionId: item?.id});
    }
    setCollectionSelected(item);
  };

  const handleOnCheck = (item: ActionParams, isSub?: boolean) => {
    if (!collectionStickers) return;
    const exists = collectionStickers.find((i) => i.id === item.id);
    const subcollections = item.subCollections || [];
    let listToSave = collectionStickers;
    const sortOrderSeq = listToSave.at(-1)?.sortOrder || -1;
    const newItem = {
      id: item.id,
      name: item.name,
      parentName: item.parentName,
      sortOrder: sortOrderSeq + 1,
    };

    if (isSub) {
      if (exists) {
        const listWithoutItem = collectionStickers.filter(
          (i) => i.id !== item.id,
        );
        if (onSave) return onSave(listWithoutItem);
      } else {
        if (onSave) return onSave([...collectionStickers, newItem]);
      }
    }

    const subIdsList = subcollections?.map((i, index) => ({
      id: i.id,
      name: i.name,
      parentName: item.name,
      sortOrder: newItem.sortOrder + index + 1,
    }));

    const list = [...subIdsList, newItem];

    if (exists) {
      listToSave = collectionStickers.filter(
        (i) => !list.find((l) => i.id === l.id),
      );
    } else {
      const newItems = list.filter(
        (i) => !collectionStickers.find((l) => i.id === l.id),
      );
      listToSave = [...listToSave, ...newItems];
    }
    if (onSave) onSave(listToSave);
  };

  const handleAddStickers = () => {
    if (!collectionStickers) return;
    const collectionId = +queryParams.collectionId;
    const subCollectionId = +queryParams.subCollectionId;
    let listToSave = collectionStickers;
    const sortOrderSeq = listToSave.at(-1)?.sortOrder || -1;

    if (subCollectionId && collectionSelected) {
      const exists = listToSave.find((i) => i.id === subCollectionId);
      const newItem = {
        id: subCollectionId,
        name: collectionSelected.name,
        parentName: collectionSelected.parentName,
        sortOrder: sortOrderSeq + 1,
      };
      if (!exists) listToSave = [...collectionStickers, newItem];
    }

    if (collectionId && collectionSelected && !subCollectionId) {
      const exists = listToSave.find((i) => i.id === collectionId);
      const subCollections = collectionSelected.subCollections || [];

      const subList = subCollections
        .filter((i) => !listToSave.find((l) => l.id === i.id))
        .map((i, index) => ({
          id: i.id,
          name: i.name,
          parentName: collectionSelected.name,
          sortOrder: sortOrderSeq + index + 2,
        }));

      if (!exists) {
        listToSave.push({
          id: collectionId,
          name: collectionSelected.name,
          sortOrder: sortOrderSeq + 1,
        });
      }

      listToSave = [...listToSave, ...subList];
    }

    if (onSave) onSave(listToSave);
  };

  const handleSearchChange = (search: string) => {
    setQueryParams({...queryParams, search});
  };

  const handleReload = () => {
    setCollectionsOffset(0);
    dispatch(resetCollectionDetails());
  };

  return (
    <>
      <div id="CollectionsPanel">
        <div className="list-toolbar">
          <InputGroup className="search-bar-group search-bar-list">
            <InputGroup.Text>
              <Search />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Collections"
              onChange={debounce((event) => {
                handleSearchChange(event.target.value);
              }, 500)}
              aria-label="SearchBar to filter by design templates name"
            />
          </InputGroup>
          <div
            className="collections-reload-button"
            onClick={() => handleReload()}
          >
            <ArrowClockwise />
          </div>
        </div>

        <div className="collections-list-box" ref={navigationRef}>
          {collectionsFetchStatus === ELoadingStates.LOADING &&
            !collections.length && <Loading spinnerOnly />}
          {collectionsFetchStatus === ELoadingStates.LOADED &&
            !collections.length &&
            !queryParams.search.length && (
              <div className="empty-collections-list">
                <Icon name="emptyFolder" />
                <span>{`No Collections.`}</span>
                <Link
                  className="empty-collection-button"
                  to="/designs/collections"
                  target="_blank"
                >
                  Create collection
                </Link>
              </div>
            )}
          {!collections.length &&
            collectionsFetchStatus === ELoadingStates.LOADED &&
            !!queryParams.search.length && (
              <SearchNotFound entity="Collections" />
            )}

          {!!collections.length && (
            <>
              <CollectionBreadcrumb items={breadcrumbItems} isLink />

              {!collectionSelected && (
                <div>
                  {collections.map((c) => (
                    <CollectionsPanelOption
                      key={c.id}
                      id={c.id}
                      name={c.name}
                      description={c.description}
                      imagesCount={c.imagesCount}
                      subCollections={c.subCollections}
                      onClick={(c) => handleOnClick(c)}
                      onCheck={(c) => handleOnCheck(c)}
                      checked={!!collectionStickers.find((l) => l.id === c.id)}
                    />
                  ))}
                </div>
              )}
              {!!collectionSelected && (
                <>
                  {collectionSelected?.subCollections?.map((c) => (
                    <CollectionsPanelOption
                      key={c.id}
                      id={c.id}
                      name={c.name}
                      description={c.description}
                      imagesCount={c.imagesCount}
                      parentName={collectionSelected.name}
                      isSubItem
                      onClick={(c) => handleOnClick(c, true)}
                      onCheck={(c) => handleOnCheck(c, true)}
                      checked={!!collectionStickers.find((l) => l.id === c.id)}
                    />
                  ))}
                  <ImagesByCollection
                    collectionId={collectionSelected.id}
                    onSelect={handleAddStickers}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionList;
