import React from 'react';
import {Button, Dropdown, Alert} from 'react-bootstrap';
import {ILabelImage} from '@gfxco/contracts';
import {ThreeDots} from 'react-bootstrap-icons';
import Loading from '../../components/Loading';
import SectionHeader from '../../components/SectionHeader';
import ContentLayout from '../../components/ContentLayout';
import ContentMain from '../../components/ContentLayout/ContentMain';
import ContentSidebar from '../../components/ContentLayout/ContentSidebar';
import FileDropzone from '../../components/FileDropzone';
import GFXImage from '../../components/Image/Image';

import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

import uploadImage, {updateFileName} from '../../libs/uploadImage';
import labelApi from '../../api/labels';

import {ReactComponent as LabelPreview} from './assets/LabelPreview.svg';
import {ReactComponent as LabelPlaceholder} from './assets/LabelPlaceholder.svg';
import LongSleeve from './assets/LongSleeve.jpg';
import Sweatshirt from './assets/Sweatshirt.jpg';
import Shirt from './assets/Shirt.jpg';
import Hoodie from './assets/Hoodie.jpg';
import Kid from './assets/Kid.jpg';
import NeckPreview from './assets/NeckPreview.jpg';

import './LabelsPage.scss';

interface LabelsPageProps {}

const CURRENT_DEFAULT_PRINTER = 'PRINTFUL';

const LabelsPage: React.FunctionComponent<LabelsPageProps> = () => {
  const [showDropzone, setShowDropzone] = React.useState<boolean>(false);
  const [productType, setProductType] = React.useState<string>('Shirt');
  const [labelImage, setLabelImage] = React.useState<ILabelImage>();
  const [isLabelLoading, setIsLabelLoading] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<string>('');
  const shopSelected = useAppSelector(selectShop);
  const shopId = React.useMemo(() => shopSelected?.id, [shopSelected]);

  const limits = {width: 1000, height: 1000, weight: 50, numberOfFiles: 1};

  React.useEffect(() => {
    fetchLabel();
  }, [shopId]);

  const fetchLabel = async () => {
    setShowErrorMessage('');

    if (!shopId) {
      return;
    }

    setIsLabelLoading(true);
    setLabelImage(undefined);

    try {
      const label = await labelApi.getLabel(shopId);
      if (label) {
        setLabelImage(label);
      }
    } catch (error) {
      setShowErrorMessage('Error getting label image');
    }

    setIsLabelLoading(false);
  };

  const handleFileAdded = async (files: File[]) => {
    if (files.length > 1 || files.length === 0) {
      return;
    }

    setIsLabelLoading(true);
    setShowErrorMessage('');

    const file = files[0];
    const newFileName = updateFileName('label-shop', file.type);

    try {
      const uploadedFile = await uploadImage({
        file,
        fileName: newFileName,
        shopId: shopId!,
        fileFolder: 'labels',
        limits,
        omitImageReference: true,
      });

      const newLabelImage: ILabelImage = {
        id: labelImage?.id,
        imageUrl: `${uploadedFile.imageUrl!}?${Date.now()}`,
        shopId: shopId!,
        printer: CURRENT_DEFAULT_PRINTER,
      };

      if (!labelImage?.id) {
        newLabelImage.id = await labelApi.addLabel(newLabelImage);
      } else {
        await labelApi.updateLabel(labelImage.id, newLabelImage);
      }
      setLabelImage(newLabelImage);
      setShowDropzone(false);
    } catch (error) {
      setShowErrorMessage('Error updating label image');
    }
    setIsLabelLoading(false);
  };

  const handleReplaceLabelClick = async () => {
    setShowDropzone(true);
  };

  const handleRemoveLabelClick = async () => {
    if (!labelImage?.id) {
      return;
    }

    setIsLabelLoading(true);

    try {
      await labelApi.deleteLabel(labelImage?.id);
      setLabelImage(undefined);
    } catch (error) {
      setShowErrorMessage('Error deleting label image');
    }

    setIsLabelLoading(false);
  };

  const imageUrl = React.useMemo(
    () => labelImage?.imageUrl,
    [labelImage?.imageUrl],
  );

  const getPreviewImage = () => {
    switch (productType) {
      case 'LongSleeve':
        return LongSleeve;
      case 'Sweatshirt':
        return Sweatshirt;
      case 'Hoodie':
        return Hoodie;
      case 'Kid':
        return Kid;
      default:
        return Shirt;
    }
  };

  return (
    <div id="LabelsPage">
      <SectionHeader
        title="Labels"
        subtitle="Upload a logo to personalize your labels."
      />
      <ContentLayout>
        <ContentSidebar>
          {labelImage && !showDropzone ? (
            <div className="image-item">
              <Dropdown
                className="image-menu-icon"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Dropdown.Toggle variant="outline-light">
                  <ThreeDots />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleReplaceLabelClick}>
                    Replace
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleRemoveLabelClick}>
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {labelImage.imageUrl && (
                <div className="label-image">
                  <GFXImage
                    src={imageUrl}
                    placeholderSrc="/loading-render.gif"
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              {isLabelLoading ? (
                <Loading />
              ) : (
                <>
                  <h2 className="section-title">Upload your art:</h2>
                  <FileDropzone
                    limits={limits}
                    allowSelectImages={true}
                    onFileAdded={handleFileAdded}
                    dropzoneText={
                      <p>
                        Create your first{' '}
                        <span className="bold-text">
                          label and add it to your designs
                        </span>
                      </p>
                    }
                    callToAction={
                      <Button className="labels-button">
                        + Add label image
                      </Button>
                    }
                  />
                  {showDropzone && (
                    <Button
                      variant="secondary"
                      onClick={() => setShowDropzone(false)}
                    >
                      Cancel
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          <div className="label-preview">
            <h2 className="section-title">Sample label preview</h2>
            {labelImage?.imageUrl ? (
              <div className="label-preview-image">
                <GFXImage src={imageUrl} placeholderSrc="/loading-render.gif" />
              </div>
            ) : (
              <div className="label-preview-placeholder">
                <LabelPlaceholder />
              </div>
            )}
            <LabelPreview />
          </div>
          {showErrorMessage && (
            <div className="label-error-message">
              <Alert variant="danger">{showErrorMessage}</Alert>
            </div>
          )}
        </ContentSidebar>
        <ContentMain>
          <div className="content-section content-section-single-column">
            <h2 className="section-title">Label preview</h2>
            <div className="preview-section">
              <div className="preview-image">
                <GFXImage src={getPreviewImage()} alt="Preview" />
              </div>
              <div className="neck-preview">
                {labelImage?.imageUrl ? (
                  <div className="neck-preview-image">
                    <GFXImage
                      src={imageUrl}
                      placeholderSrc="/loading-render.gif"
                    />
                  </div>
                ) : (
                  <div className="neck-placeholder">
                    <LabelPlaceholder />
                  </div>
                )}
                <GFXImage src={NeckPreview} alt="Preview" />
              </div>
            </div>
            <div className="product-type-buttons">
              <Button
                className={productType === 'Shirt' ? 'selected' : ''}
                onClick={() => setProductType('Shirt')}
              >
                T-Shirts
              </Button>
              <Button
                className={productType === 'LongSleeve' ? 'selected' : ''}
                onClick={() => setProductType('LongSleeve')}
              >
                Longsleeves
              </Button>
              <Button
                className={productType === 'Sweatshirt' ? 'selected' : ''}
                onClick={() => setProductType('Sweatshirt')}
              >
                Sweatshirts
              </Button>
              <Button
                className={productType === 'Hoodie' ? 'selected' : ''}
                onClick={() => setProductType('Hoodie')}
              >
                Hoodies
              </Button>
              <Button
                className={productType === 'Kid' ? 'selected' : ''}
                onClick={() => setProductType('Kid')}
              >
                Youth
              </Button>
            </div>
            <div className="horizontal-divider"></div>
            <div className="information-text">
              <p>
                For your label logo art, the minimum sizes are:{' '}
                <span className="information-emphasis">
                  1.5x1.5 inches or 100x100 pixels art file should be PNG
                </span>
              </p>
              <p className="label-disclosure">
                *The size and washing specifications are for reference only.
                They do not reflect the final product information.
              </p>
              <p className="designer-tip">
                When you create a template, make sure your product selection has
                labels available.
                <br /> Please reference our catalog and check out our{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://gfx-help.notion.site/Help-Center-1ed49d73504a42f482a9a3257c635fe2"
                >
                  Help Center
                </a>{' '}
                to learn more about labels
              </p>
            </div>
          </div>
        </ContentMain>
      </ContentLayout>
    </div>
  );
};

export default LabelsPage;
