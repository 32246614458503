import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import debounce from 'lodash.debounce';
import {SESSION_STORAGE_KEYS} from '../../constants';

// Components
import ShopifyIntegrationSection from './panels/ShopifyIntegrationSection';
import StoreSection from './panels/StoreSection';
import GFXButton from '../../components/Button';
import Icon from '../../components/Icons/Icon';
import GFXStoreItemComponent from '../../components/GFXStoreItemComponent/GFXStoreItemComponent';
import {Form, InputGroup} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import CreateNewStoreForm from './CreateNewStoreForm/CreateNewStoreForm';

// Hooks
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectShops} from '../../features/shops/loadShops';
import {
  selectShop,
  updateSelectedShop,
} from '../../features/shops/selectedShop';
import {selectUserPermissions} from '../../features/user';
import useSessionStorage from '../../hooks/useSessionStorage';

// Contracts
import {ShopsResponse, constants} from '@gfxco/contracts';

// Data
import {ColorOptions} from './storeColorOptions.data';

import './MyStorePage.scss';

const MyStorePage: React.FC = () => {
  const [shopsAvailable, setShopsAvailable] = useState<ShopsResponse[]>([]);
  const [shopFilter, setShopFilter] = useState<string>('');
  const [showNewStoreModal, setShowNewStoreModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const {setValue} = useSessionStorage();
  const shops = useAppSelector(selectShops);
  const shopSelected = useAppSelector(selectShop);
  const userPermissions = useAppSelector(selectUserPermissions);

  useEffect(() => {
    if (searchParams.get('newStore') === 'true') {
      setShowNewStoreModal(true);
    } else {
      setShowNewStoreModal(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (shops) {
      const shopsFiltered = shops.filter(
        (shop) =>
          shopFilter === '' ||
          shop.name.toLowerCase().includes(shopFilter.toLowerCase()),
      );
      setShopsAvailable(shopsFiltered);
    }
  }, [shops, shopFilter]);

  const openNewStoreModal = (show: boolean) => {
    setSearchParams({newStore: show.toString()});
  };

  if (!shopSelected) {
    return <></>;
  }

  return (
    <div id="MyStorePage" className="MyStorePage">
      <header className="MyStorePage__header">
        <div className="store-header">
          <div className="store-header__title">
            <h3>My Store</h3>
            <p>Manage your store(s) and their configuration.</p>
          </div>

          {userPermissions &&
            userPermissions.includes(constants.USER_PERMISSIONS.ALL) && (
              <div className="store-header__callToAction">
                <GFXButton
                  variant="primary"
                  className="store-header__callToAction__button"
                  onClick={() => {
                    openNewStoreModal(true);
                  }}
                >
                  <div style={{display: 'flex'}}>
                    <Icon name="createNewStore" />
                    <span style={{alignSelf: 'center'}}>
                      Create a new store
                    </span>
                  </div>
                </GFXButton>
              </div>
            )}
        </div>
      </header>

      <main className="MyStorePage__main">
        <aside className="section preview-section">
          <div id="MyStorePage__main__search">
            <InputGroup>
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                className="search-input"
                placeholder="Search"
                aria-label="Search for store"
                onChange={debounce((event) => {
                  setShopFilter(event.target.value);
                }, 500)}
              />
            </InputGroup>
            <GFXButton
              variant="primary"
              className="MyStorePage__main__search__button"
              onClick={() => {
                openNewStoreModal(true);
              }}
            >
              <Icon name="createNewStore" fillColor={{default: '#4573BB'}} />
            </GFXButton>
          </div>
          {shopsAvailable?.map((shop) => (
            <GFXStoreItemComponent
              key={shop.id}
              shop={shop}
              isSelectedStore={shopSelected.id === shop.id}
              onClick={() => {
                if (shopSelected.id !== shop.id) {
                  setValue(
                    SESSION_STORAGE_KEYS.SELECTED_SHOP,
                    shop.id.toString(),
                  );
                  dispatch(updateSelectedShop(shop));
                }
              }}
            />
          ))}
        </aside>
        <div className="integrations">
          <StoreSection className="section" />
          <ShopifyIntegrationSection className="section" />
        </div>
      </main>
      <CreateNewStoreForm
        show={showNewStoreModal}
        onClose={() => {
          openNewStoreModal(false);
        }}
        colorOptions={ColorOptions}
      />
    </div>
  );
};

export default MyStorePage;
