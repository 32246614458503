import {
  ELoadingStates,
  FULFILLMENT_SERVICE,
  IGetFulfillmentServices,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getFulfillmentServices} from '../../api';
import {RootState} from '../../app/store';

interface FulfillmentServiceState {
  services: IGetFulfillmentServices;
  fetchStatus: ELoadingStates;
}

const initialState: FulfillmentServiceState = {
  services: {
    [FULFILLMENT_SERVICE.PRINTFUL]: false,
    [FULFILLMENT_SERVICE.ZIFT]: false,
    [FULFILLMENT_SERVICE.SEND_ARTS]: false,
  },
  fetchStatus: ELoadingStates.IDLE,
};

export const getFulfillmentServicesAsync = createAsyncThunk(
  'fulfillmentService/fetch',
  async (shopId: number) => {
    const response = await getFulfillmentServices(shopId);
    return response;
  },
);

export const loadFulfillmentServices = createSlice({
  name: 'shopPrinters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFulfillmentServicesAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getFulfillmentServicesAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.fetchStatus = ELoadingStates.LOADED;
          state.services = action.payload;
        }
      })
      .addCase(getFulfillmentServicesAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectFulfillmentServiceStatusByService = (
  state: RootState,
  service: FULFILLMENT_SERVICE,
) => {
  return state.fulfillmentService.services[service];
};

export const selectFulfillmentServiceStatus = (state: RootState) => {
  return state.fulfillmentService.services;
};

export const selectFulfillmentServiceFetchStatus = (state: RootState) => {
  return state.fulfillmentService.fetchStatus;
};

export default loadFulfillmentServices.reducer;
