import React from 'react';
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import Icon from '../Icons/Icon';

import './NotFound.scss';
interface NotFoundProps {
  title?: string;
  message?: string;
}

const NotFound: React.FC<NotFoundProps> = ({
  title = 'Opps!',
  message = 'It looks like the item you are looking for cannot be found.',
}) => {
  const navigate = useNavigate();
  return (
    <div id="Not-found">
      <Icon name="oopsEmpty" />
      <div>
        <h1 className="not-found__title">{title}</h1>
        <p className="not-found__message">{message}</p>
        <Button
          className="not-found__back-button"
          variant="primary"
          onClick={() => navigate('/')}
        >
          Go back home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
