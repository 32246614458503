import React from 'react';

import './StepItem.scss';
import {ChevronUp, ChevronDown} from 'react-bootstrap-icons';
import Loading from '../../../components/Loading';

interface RequiredProps {
  index: number;
  title: string;
  status: 'completed' | 'not-completed' | 'no-status';
  description: React.ReactNode;
  loading: boolean;
}

const StepItem: React.FC<RequiredProps> = ({
  title,
  status,
  index,
  description,
  loading = true,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const isCompleted = status === 'completed';
  const isNoStatus = status === 'no-status';

  return (
    <div
      className={`step-box ${open && 'step-box-open'}`}
      onClick={() => {
        if (!open) {
          setOpen(!open);
        }
      }}
    >
      <div
        className="box-header"
        onClick={() => {
          if (open) {
            setOpen(!open);
          }
        }}
      >
        <b>{index}.</b>
        <span className="box-header-title">{title}</span>
        <div className="box-header-status">
          {!loading && !isNoStatus && (
            <span
              className={
                isCompleted ? 'status-completed' : 'status-not-completed'
              }
            >
              {isCompleted ? 'Completed!' : 'Incomplete'}
            </span>
          )}
          {loading && <Loading spinnerOnly />}
          <div onClick={() => setOpen(!open)}>
            {open ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      </div>
      {open && <div className="box-description">{description}</div>}
    </div>
  );
};

export default StepItem;
