import React from 'react';
import {
  ELoadingStates,
  FULFILLMENT_SERVICE,
  IGetFulfillmentServices,
} from '@gfxco/contracts';
import {OrderCard} from '../../../components/OrderCard';
import {OrderModal} from '../../../components/OrderModal';
import Loading from '../../../components/Loading';
import IntegrationWarningModal from '../../../components/IntegrationWarningModal';

import {IModerationOrderExtended} from '../../../features/moderation/loadModerationOrders';

import './ModerationContent.scss';

type ModerationContentProps = {
  orders: IModerationOrderExtended[];
  ordersFetchStatus: ELoadingStates;
  printerFetchStatus: ELoadingStates;
  printerStatus: IGetFulfillmentServices;
  allSelected?: boolean;
  onSelectItem?: (itemId: number, selected: boolean) => void;
  onReset: () => void;
};

const ModerationContent: React.FC<ModerationContentProps> = (props) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [showIntegrationWarning, setShowIntegrationWarning] =
    React.useState<boolean>(false);
  const [itemSelected, setItemSelected] = React.useState<number>();
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const handleViewDesign = (itemId: number) => {
    if (!checkValidPrinterStatus(itemId)) {
      setShowIntegrationWarning(true);
      return;
    }

    const index = props.orders.findIndex((o) => o.itemId === itemId);
    setItemSelected(itemId);
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const handleNextViewDesign = () => {
    if (typeof currentIndex === 'undefined') return;
    const newOrderToView = props.orders[currentIndex + 1];
    if (newOrderToView) {
      setCurrentIndex(currentIndex + 1);
      setItemSelected(newOrderToView.itemId);
    }
  };

  const handlePreviousViewDesign = () => {
    if (typeof currentIndex === 'undefined') return;
    const newOrderToView = props.orders[currentIndex - 1];
    if (newOrderToView) {
      setCurrentIndex(currentIndex - 1);
      setItemSelected(newOrderToView.itemId);
    }
  };

  const checkValidPrinterStatus = (itemSelected?: number) => {
    if (!itemSelected) {
      return false;
    }
    const order = props.orders.find((order) => order.itemId === itemSelected);
    if (!order) {
      return false;
    }
    if (props.printerStatus[FULFILLMENT_SERVICE.SEND_ARTS]) {
      return true;
    }
    if (!order.printInfo || !order.printInfo.printer) {
      return false;
    }

    if (
      typeof order.printInfo.printer !== 'string' ||
      !(order.printInfo.printer in FULFILLMENT_SERVICE)
    ) {
      return false;
    }

    return props.printerStatus[order.printInfo.printer as FULFILLMENT_SERVICE];
  };

  return (
    <>
      <OrderModal
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
          props.onReset();
        }}
        itemSelected={props.orders.find(
          (order) => order.itemId === itemSelected,
        )}
        onNext={handleNextViewDesign}
        onPrevious={handlePreviousViewDesign}
        currentIndex={currentIndex}
        totalOrders={props.orders.length}
        printerStatus={checkValidPrinterStatus(itemSelected)}
        printerFetchStatus={props.printerFetchStatus}
      />

      <IntegrationWarningModal
        show={showIntegrationWarning}
        onClose={() => setShowIntegrationWarning(false)}
      />

      {props.ordersFetchStatus === ELoadingStates.IDLE && <></>}

      <section className="moderationByShopPage__ordersList">
        {props.orders.map((order, index: number) => (
          <OrderCard
            key={`order-${index}`}
            viewDesignAction={handleViewDesign}
            item={order}
            selected={order.selected}
            onSelectChange={(selected) => {
              if (props.onSelectItem) {
                props.onSelectItem(order.itemId, selected);
              }
            }}
          />
        ))}
      </section>
      {props.ordersFetchStatus === ELoadingStates.LOADING && <Loading />}
      {props.ordersFetchStatus === ELoadingStates.FAILED && <span>Error</span>}
    </>
  );
};

export default ModerationContent;
