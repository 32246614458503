export const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const parseStripeAmount = (amount: number) => {
  // Check if amount is a valid integer
  if (!Number.isInteger(amount)) {
    throw new Error('Invalid amount value');
  }

  // Format the amount value with currency symbol and decimal places
  const formattedAmount = `$${(amount / 100).toFixed(2)}`;

  return formattedAmount;
};

export const formatDate = (date: Date) => {
  const parsedDate = new Date(date);
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = parsedDate.getDate().toString().padStart(2, '0');
  const year = parsedDate.getFullYear().toString();
  const hours = parsedDate.getHours().toString().padStart(2, '0');
  const minutes = parsedDate.getMinutes().toString().padStart(2, '0');

  return `${month}/${day}/${year}, ${hours}:${minutes}`;
};

export const truncateString = (str: string, num: number): string => {
  if (!str) {
    return '';
  }
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};
