import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import authReducer from '../features/auth';
import userReducer from '../features/user';
import shopReducer from '../features/shops/loadShops';
import developerOptionReducer from '../features/shops/loadDeveloperOptions';
import selectedShopReducer from '../features/shops/selectedShop';
import moderationOrdersReducer from '../features/moderation/loadModerationOrders';
import designsCountReducer from '../features/moderation/loadDesignsCount';
import moderationFiltersReducer from '../features/moderation/loadFilters';
import collectionsDetailsReducer from '../features/collections/loadCollectionsDetails';
import designTemplatesReducer from '../features/designTemplates/loadDesignTemplates';
import draftCanvasReducer from '../features/draftCanvas/loadDraftCanvas';
import servicesReducer from '../features/services/loadServices';
import exceptionsReducer from '../features/exceptions/loadExceptions';
import integrationsReducer from '../features/integrations/loadShopifyIntegration';
import printersIntegrationReducer from '../features/integrations/loadPrintersIntegration';
import publishedProductsReducer from '../features/publishedProducts/loadPublishedProducts';
import imagesReducer from '../features/images/loadImages';
import collectionsReducer from '../features/collections/loadCollections';
import collectionsCountReducer from '../features/collections/loadCollectionsCount';
import installationConfigsReducer from '../features/shops/loadInstallationConfigs';
import stickerImagesReducer from '../features/stickers/loadStickersImages';
import stickerCollectionsReducer from '../features/stickers/loadStickersCollections';
import linkedSlotsReducer from '../features/linkedSlots/loadLinkedSlots';
import fulfillmentServiceReducer from '../features/fulfillmentService/loadFulfillmentService';
import fontsReducer from '../features/fonts/loadFonts';

// middlewares
import selectedShopMiddleware from '../middlewares/shops/selectedShopMiddleware';
import authMiddleware from '../middlewares/auth/authMiddleware';

// Helpers
import {reloadAllClients} from '../api';

const appReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  shops: shopReducer,
  moderationOrders: moderationOrdersReducer,
  developerOptions: developerOptionReducer,
  selectedShop: selectedShopReducer,
  collectionsDetails: collectionsDetailsReducer,
  designTemplates: designTemplatesReducer,
  draftCanvas: draftCanvasReducer,
  designsCount: designsCountReducer,
  moderationFilters: moderationFiltersReducer,
  services: servicesReducer,
  exceptions: exceptionsReducer,
  integrations: integrationsReducer,
  publishedProducts: publishedProductsReducer,
  printersIntegration: printersIntegrationReducer,
  images: imagesReducer,
  collections: collectionsReducer,
  collectionsCount: collectionsCountReducer,
  installationConfigs: installationConfigsReducer,
  stickerImages: stickerImagesReducer,
  stickerCollections: stickerCollectionsReducer,
  linkedSlots: linkedSlotsReducer,
  fulfillmentService: fulfillmentServiceReducer,
  fonts: fontsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'system/ResetAll') {
    reloadAllClients();
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'auth/currentAuthenticatedUser/fulfilled',
          'auth/login/fulfilled',
        ],
      },
    })
      .prepend(selectedShopMiddleware)
      .prepend(authMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
