import React from 'react';
import './Toggle.scss';

interface GFXToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelOn?: string;
  labelOff?: string;
}

const GFXToggle: React.FC<GFXToggleProps> = (props) => {
  const {label, labelOn, labelOff, ...restProps} = props;

  const labelOnText = label ?? labelOn ?? 'On';
  const labelOffText = label ?? labelOff ?? 'Off';

  return (
    <div className={`gfx-switch ${props.checked ? 'checked' : ''}`}>
      <label className="gfx-switch-label">
        {props.checked ? labelOnText : labelOffText}
      </label>
      <input
        {...restProps}
        type="checkbox"
        className={`gfx-switch-input ${props.className ?? ''}`}
      />
    </div>
  );
};

export default GFXToggle;
