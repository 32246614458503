import {useGFXInstance} from '@best-apps/gfx-editor';
import Icon from '../../Icons/Icon';
import React from 'react';

export const ScaleTopToolbar = () => {
  const gfx = useGFXInstance();
  const [scaleMode, setScaleMode] = React.useState<boolean>(false);
  const [initialState, setInitialState] = React.useState<
    {
      scaleX: number;
      scaleY: number;
      left: number;
      uuid: string;
      top: number;
      width: number;
      height: number;
    }[]
  >([]);

  React.useEffect(() => {
    if (!scaleMode) {
      return;
    }
    const currentSection = gfx?.state.activeSection || 'front';
    const objects = gfx?.state.design?.sections[currentSection]?.objects || [];
    if (objects.length > 0) {
      const initialState = objects.map((object: any) => {
        return {
          scaleX: object.scaleX,
          scaleY: object.scaleY,
          uuid: object.uuid,
          left: object.left,
          top: object.top,
          width: object.width,
          height: object.height,
        };
      });
      setInitialState(initialState);
    }
  }, [scaleMode]);

  React.useEffect(() => {
    setScaleMode(gfx?.state.groupMode ?? false);
  }, [gfx?.state.groupMode]);

  if (!scaleMode) {
    return null;
  }

  const increaseScale = () => {
    if (gfx) {
      gfx.actions.setScaleCenter(1.1);
    }
  };

  const decreaseScale = () => {
    if (gfx) {
      gfx.actions.setScaleCenter(0.9);
    }
  };

  const fitToPrintableArea = () => {
    if (gfx) {
      gfx.actions.fitGroupToPrintableArea();
    }
  };

  const toggleGroup = () => {
    if (gfx) {
      gfx.actions.toggleGroupAllObjects();
    }
  };

  const discardChanges = async () => {
    if (gfx && scaleMode) {
      await gfx.actions.toggleGroupAllObjects();
      await gfx.actions.applySlotProperties(initialState);
      setInitialState([]);
    }
  };

  return (
    <>
      <div className="gfx-builder-toolbar top-builder-toolbar top-scale-toolbar">
        <div className="top-builder-toolbar__description-ico">
          <Icon name="scaleIcon" box boxHex="#4573BB" />
        </div>
        <div className="icon-group">
          <div className="top-builder-toolbar__text">
            <p>FIT TO PRINTABLE AREA</p>
          </div>
          <Icon
            box
            name="fullScreen"
            tooltip="Scale Design"
            action={fitToPrintableArea}
            tooltipPosition="bottom"
            boxHex="#F6F6F6"
            fillColor={{
              default: '#838383',
              hover: '#4573BB',
            }}
          />
        </div>
        <div className="icon-group">
          <div className="top-builder-toolbar__text">
            <p>ART SIZE</p>
          </div>
          <Icon
            box
            name="plusIcon"
            tooltip="Increase scale"
            tooltipPosition="bottom"
            action={increaseScale}
            boxHex="#F6F6F6"
            fillColor={{
              default: '#838383',
              hover: '#4573BB',
            }}
          />
          <Icon
            box
            name="lessIcon"
            tooltip="Decrease scale"
            tooltipPosition="bottom"
            action={decreaseScale}
            boxHex="#F6F6F6"
            fillColor={{
              default: '#838383',
              hover: '#4573BB',
            }}
          />
        </div>
        <div className="top-scale-toolbar__confirmation-buttons">
          <div className="buttons">
            <Icon
              box
              name="checkBordered"
              tooltip="Complete Scale"
              tooltipPosition="bottom"
              action={toggleGroup}
            />
            <Icon
              box
              name="xBordered"
              tooltip="Discard Changes"
              tooltipPosition="bottom"
              action={discardChanges}
            />
          </div>
        </div>
      </div>
    </>
  );
};
