import {
  ISlotTemplateCanvas,
  ProductSkusRequest,
  ProductSkusResponse,
  ProductTypeDBModel,
} from '@gfxco/contracts';
import './DuplicateForms.scss';
import React from 'react';
import {useAppSelector} from '../../../../app/hooks';
import {selectShop} from '../../../../features/shops/selectedShop';
import {IDuplicateFormValues} from '../DuplicateTypes';
import classNames from 'classnames';
import {getProductSkus} from '../../../../api';
import ProductSku from '../../../SelectProductSku/ProductSku';
import Loading from '../../../Loading';
import ProductTypeComponent from '../../../ProductTypeComponent/ProductTypeComponent';

type FormProps = {
  productList?: ProductTypeDBModel[];
  formValues: IDuplicateFormValues;
  setValue: (key: string, value: any) => void;
  templateName?: string;
  templateCanvas?: ISlotTemplateCanvas;
};

export const FirstStepComponent: React.FC<FormProps> = (props) => {
  const placeholderName = props.templateName
    ? `Copy of ${props.templateName}`
    : 'Enter new product name';
  return (
    <div className="basic-duplicate-information">
      <div className="duplicate-form-item">
        <label>1. New product name</label>
        <input
          className="product-name"
          placeholder={placeholderName}
          type="text"
          name="name"
          onChange={(e) => props.setValue('name', e.target.value)}
        />
      </div>
      <div className="duplicate-form-item">
        <label>2. Select new Product type: </label>
        <div className="product-types-listing product-type-list">
          {props.productList?.map((product) => {
            const classes = classNames({
              'product-type-item': true,
              selected: props.formValues.garmentType === product.code,
            });
            return (
              <ProductTypeComponent
                key={product.code}
                isSelected={props.formValues.garmentType === product.code}
                classes={classes}
                product={product}
                setValue={props.setValue}
                formValues={props.formValues}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SecondStepComponent: React.FC<FormProps> = (props) => {
  const selectedShop = useAppSelector(selectShop);
  const shopId = selectedShop!.id;

  const productTypeChoosen = props.formValues.garmentType;
  const [printerSkus, setPrinterSkus] = React.useState<ProductSkusResponse[]>(
    [],
  );
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    getProductSkusData();
  }, []);

  const getProductSkusData = async () => {
    try {
      if (!shopId) return;
      const params: ProductSkusRequest = {
        productType: productTypeChoosen,
        shopId,
      };
      setIsLoading(true);
      const productSkus = await getProductSkus(params);
      if (productSkus) {
        setPrinterSkus(productSkus);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!productTypeChoosen) return null;

  return (
    <div className="basic-duplicate-information">
      <div className="duplicate-form-item">
        <label>3. Select your printer</label>
        <div className="product-types-listing product-printers">
          {isLoading ? <Loading /> : null}
          {printerSkus.map((item) => (
            <ProductSku
              key={`${item.description}-${item.printer}`}
              item={item}
              productType={productTypeChoosen}
              isSelected={
                props.formValues.skuInfo?.description === item.description &&
                props.formValues.skuInfo?.printer === item.printer
              }
              handleOnClick={(sku) => {
                props.setValue('skuInfo', {
                  ...sku,
                });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const FramedPosterFormComponent: React.FC<FormProps> = (props) => {
  const sides = [
    {
      code: 'front',
      name: 'Front Side',
      thumbnail_url: props.templateCanvas?.proofFront,
    },
    {
      code: 'back',
      name: 'Back Side',
      thumbnail_url: props.templateCanvas?.proofBack,
    },
  ];

  const handleOnChange = (key: string, value: any) => {
    key = 'sideToClone';
    props.setValue(key, value);
  };

  return (
    <div className="framedposter-duplicate-information">
      <div className="duplicate-form-item">
        <label>4. Select the side you want copied on to the poster</label>
        <span>
          The original product contains front and back designs, which one do you
          want to copy?
        </span>
        <div className="product-sides-listing product-printers">
          {sides.map((item) => (
            <ProductTypeComponent
              key={item.code}
              classes={classNames({
                'product-type-item': true,
                selected: props.formValues.garmentType === item.code,
              })}
              isSelected={props.formValues.sideToClone === item.code}
              product={item}
              setValue={handleOnChange}
              formValues={props.formValues}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  FirstStepComponent,
  SecondStepComponent,
  FramedPosterFormComponent,
};
