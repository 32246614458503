import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ChevronDown, ChevronUp, X} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import {ICollectionEntity, ICollectionResponse} from '@gfxco/contracts';

import WarningTooltip from '../../WarningTooltip';

import {ReactComponent as ImageIcon} from '../../../assets/icons/image-fill-icon.svg';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-down-right.svg';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-open-icon.svg';

import classNames from 'classnames';
import './CollectionOption.scss';

interface RequiredProps {
  id: number;
  name: string;
  description: string;
  isSelect: boolean;
  templateId: number | string;
}

type ICollectionType = ICollectionResponse & {blocked?: boolean};

interface OptionalProps {
  onDelete?: (
    evt: any,
    collectionId: number,
    name: string,
    subId?: number,
  ) => void;
  subCollections?: ICollectionEntity[];
  imagesCount?: string;
  index?: number;
  selected?: ICollectionType[];
  onChange?: (ids: ICollectionResponse[]) => void;
  blocked?: boolean;
  defaultImage?: any;
}
type CollectionOptionProps = RequiredProps & OptionalProps;

const CollectionOption: React.FC<CollectionOptionProps> = ({
  name,
  id,
  description,
  imagesCount,
  subCollections,
  isSelect,
  index,
  onDelete,
  selected = [],
  onChange,
  blocked,
  defaultImage,
  templateId,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(false);
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const hasSubCollection = subCollections && subCollections.length > 0;

  const handleOnDelete = (evt: any, subId?: number, subName?: string) => {
    let nameCollection = '';
    if (subId) {
      nameCollection = subName || '';
      if (defaultImage && subId === defaultImage.collectionId) {
        return setShowWarning(true);
      }
    } else {
      nameCollection = name;
      if (defaultImage && id === defaultImage.collectionId) {
        return setShowWarning(true);
      }
    }
    if (onDelete) onDelete(evt, id, nameCollection, subId);
  };

  const handleCheck = (collectionId: number) => {
    let collectionsSelected = selected;
    const exists = selected.find((item) => item.id === id);
    if (collectionId === id) {
      setOpen(true);
      if (!exists) {
        collectionsSelected = [
          ...collectionsSelected,
          {
            name,
            id,
            description,
            imagesCount,
            imageUrl: '',
            largeImageUrl: '',
            subCollections,
          },
        ];
      } else {
        if (exists.subCollections?.length) {
          collectionsSelected = collectionsSelected.map((i) => {
            if (i.id === collectionId) {
              return {...i, blocked: true};
            }
            return i;
          });
        } else {
          collectionsSelected = collectionsSelected.filter(
            (i) => i.id !== collectionId,
          );
        }
      }
    } else {
      if (subCollections) {
        if (!exists) {
          const subCollection = subCollections.find(
            (item) => item.id === collectionId,
          );
          if (!subCollection) return;
          collectionsSelected = [
            ...collectionsSelected,
            {
              blocked: true,
              name,
              id,
              description,
              imagesCount,
              imageUrl: '',
              largeImageUrl: '',
              subCollections: [subCollection],
            },
          ];
        } else {
          const newSelected = selected.map((s) => {
            if (s.id !== id) return s;
            const newSubs = s.subCollections?.filter(
              (sub) => sub.id !== collectionId,
            );
            return {...s, subCollections: newSubs};
          });
          collectionsSelected = newSelected;
        }
      }
    }
    if (onChange) onChange(collectionsSelected);
  };

  return (
    <div className="CollectionOption">
      <div
        className={classNames({
          'box-selected': !isSelect,
          'option-box': isSelect,
          'option-box-subcollections': hasSubCollection,
        })}
      >
        {!!index && <span>{index}</span>}
        <div
          className={classNames({
            'collection-item-title': true,
            'collection-blocked': blocked,
            'collection-item-selected': !blocked && !isSelect,
          })}
          onClick={() => {
            if (!subCollections?.length) {
              return;
            }
            setOpen(!open);
          }}
        >
          {isSelect && (
            <Form.Check
              type="checkbox"
              onChange={() => handleCheck(id)}
              onClick={(evt: any) => evt.stopPropagation()}
              checked={!!selected.find((c) => c.id === id && !c.blocked)}
            />
          )}
          <div className="collection-item-content">
            <div className="counter">
              {!!subCollections?.length && (
                <div className="item-counter item-collection-counter">
                  <FolderIcon />
                  <span>{subCollections?.length}</span>
                </div>
              )}
              {!!imagesCount && (
                <div className="item-counter">
                  <ImageIcon />
                  <span>{imagesCount}</span>
                </div>
              )}
            </div>
            <p
              className={classNames({
                'item-title': true,
                'item-title-without-subtitle': !description,
              })}
              title={name}
            >
              {name}
            </p>
            <p className="item-subtitle" title={description}>
              {description}
            </p>
          </div>
          {!blocked && hasSubCollection && (
            <div className="collection-icon">
              <ChevronUp style={{display: open ? 'block' : 'none'}} />
              <ChevronDown style={{display: open ? 'none' : 'block'}} />
            </div>
          )}
        </div>
        {!isSelect && !blocked && (
          <X onClick={(evt: any) => handleOnDelete(evt)} />
        )}
        {showWarning && (
          <WarningTooltip
            id={id}
            onClose={() => setShowWarning(false)}
            onAccept={() => navigate(`/designs/edit/${templateId}`)}
            title="Oops!"
            actionText="Open editor"
            text={
              <span>
                This collection have <i>“the main image”</i> of this slot
                design. To delete,
                <b>
                  {` open the editor and change the main collection of “${name}."`}
                </b>
              </span>
            }
          />
        )}
      </div>
      {(open || blocked) &&
        subCollections &&
        subCollections.map((sub) => (
          <div className="subitem-row" key={sub.id}>
            {isSelect && (
              <Form.Check
                type="checkbox"
                onChange={() => handleCheck(sub.id)}
                checked={
                  !!selected
                    .find((c) => c.id === id)
                    ?.subCollections?.find((s) => s.id === sub.id)
                }
              />
            )}
            <ArrowRight className="icon" />
            <div
              className={`item-container ${
                !isSelect ? 'item-container-selected' : ''
              }`}
            >
              <div className="item-counter">
                <ImageIcon />
                <span>{sub.imagesCount}</span>
              </div>
              <div className="collection-item-title">
                <span
                  className={classNames({
                    'item-title': true,
                    'item-title-without-subtitle': !description,
                  })}
                  title={sub.name}
                >
                  {sub.name}
                </span>
                <span className="item-subtitle" title={sub.description}>
                  {sub.description}
                </span>
              </div>
            </div>
            {!isSelect && (
              <X
                onClick={(evt: any) => handleOnDelete(evt, sub.id, sub.name)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default CollectionOption;
