import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import useQueryParams from '../../../hooks/useQueryParams';
import {ReactComponent as NotFoundIcon} from '../../../assets/icons/not-found-icon.svg';
import {ReactComponent as NewDesignIcon} from '../../../assets/icons/new-design-icon.svg';
import GFXButton from '../../Button/Button';
import './NoResultView.scss';

const NoResultView: React.FC = () => {
  const [queryParams] = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (queryParams.search) {
    return (
      <div id="no-result-view" className="no-result-view">
        <NotFoundIcon />
        <h3>Item not found</h3>
        <p>
          Looks like the items you&lsquo;re looking for does not exist, please
          try again
        </p>
      </div>
    );
  }

  const handleRedirectToDesigns = () => {
    return navigate(`/designs`);
  };

  if (queryParams.tab === 'published') {
    return (
      <div
        id="no-result-view"
        className="no-result-view no-result-view--bordered"
      >
        <NewDesignIcon />
        <p>
          Seems like there are not{' '}
          <a onClick={() => handleRedirectToDesigns()}>
            designs published yet.
          </a>
        </p>
        <GFXButton onClick={() => handleRedirectToDesigns()}>
          Let&lsquo;s Publish
        </GFXButton>
      </div>
    );
  }

  const handleRedirectCreateDesign = () => {
    return navigate(`/designs/create${location.search}`);
  };

  return (
    <div
      id="no-result-view"
      className="no-result-view no-result-view--bordered"
    >
      <NewDesignIcon />
      <p>
        <br />
        No designs yet.
      </p>
      <GFXButton onClick={() => handleRedirectCreateDesign()}>
        Get started
      </GFXButton>
    </div>
  );
};

export default NoResultView;
