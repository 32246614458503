import axios from 'axios';

const mockSendArtData = {
  id: 'ORD-12345',
  orderName: 'Special Order',
  customerName: 'Acme Corporation',
  items: [
    {
      id: 'ITM-1234',
      designNumber: '8512881f-a6ba-435b-84e1-770ada54fed1',
      artFront: 'https://assets.gfxco.com/example-art-front-1.png',
      artBack: 'https://assets.gfxco.com/example-art-back-1.png',
      size: 'M',
      proofFront: 'https://assets.gfxco.com/example-proof-front-1.png',
      proofBack: 'https://assets.gfxco.com/example-proof-back-1.png',
      label: 'https://assets.gfxco.com/example-label-1.png',
    },
    {
      id: 'ITM-5678',
      designNumber: 'f7ebeffe-7b1c-4b22-a882-8a7c72fc2abb',
      artFront: 'https://assets.gfxco.com/example-art-front-2.png',
      artBack: 'https://assets.gfxco.com/example-art-back-2.png',
      size: 'L',
      proofFront: 'https://assets.gfxco.com/example-proof-front-2.png',
      proofBack: 'https://assets.gfxco.com/example-proof-back-2.png',
      label: 'https://assets.gfxco.com/example-label-2.png',
    },
  ],
};

export const testSendArtsWebhook = async (webhookURL: string) => {
  try {
    await axios.post(
      webhookURL,
      {data: mockSendArtData},
      {
        timeout: 2000,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};
