import React from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Search, XCircle, ArrowClockwise} from 'react-bootstrap-icons';
import {StatusOptions} from '@gfxco/contracts';
import {getTimeFilters} from '../../../libs/getStatus';

import './TableOrderFilter.scss';
interface FilterData {
  key: string;
  label: string;
  property: string;
}
export interface RequiredProps {
  handleStatus: (key: StatusOptions) => void;
  handleTime: (key: string) => void;
  handleSearchBar: (key: string) => void;
  handleRefreshOrder: () => void;
  isLoading: boolean;
}
export interface OptionalProps {}

type TableOrderFilterProps = RequiredProps & OptionalProps;

export const TableOrderFilter: React.FC<TableOrderFilterProps> = ({
  handleStatus,
  handleTime,
  handleSearchBar,
  handleRefreshOrder,
  isLoading,
}) => {
  const times = getTimeFilters();

  const [filterData, setFilterData] = React.useState<FilterData[]>([
    {key: `all`, label: `All-time`, property: 'Date'},
  ]);

  const [inputValue, setInputValue] = React.useState('');

  const handleDelete = (filterToDelete: FilterData) => () => {
    if (filterToDelete.property === 'Status') handleStatus('');
    if (filterToDelete.property === 'Date') handleTime('');
    if (filterToDelete.property === 'Search by') handleSearchBar('');

    setFilterData((item) =>
      item.filter((filter) => filter.key !== filterToDelete.key),
    );
  };

  const handleTimeSelect = (key: any) => {
    if (key === '') {
      setFilterData((item) =>
        item.filter((filter) => filter.property !== 'Date'),
      );
      handleTime('');
      return;
    }

    handleTime(key);
    handleFilterChip({
      key,
      label: getFilterLabel(key),
      property: 'Date',
    });
  };

  const handleFilterChip = ({property, key, label}: FilterData) => {
    const filters = filterData.filter((filter) => filter.property !== property);
    filters.push({
      key,
      label,
      property,
    });
    setFilterData(filters);
  };

  const handleInputChange = (event: any) => {
    if (event.key === 'Enter') {
      handleFilterChip({
        key: `filter-${inputValue}`,
        label: inputValue,
        property: 'Search by',
      });
      handleSearchBar(inputValue);
    }
  };

  const getFilterLabel = (key: string) =>
    key === '1' ? 'Today' : key === 'all' ? 'All-time' : `Last ${key} days`;

  return (
    <div className="table-order-filter-container">
      <div className="table-order-filter">
        <InputGroup>
          <InputGroup.Text>
            <Search />
          </InputGroup.Text>
          <Form.Control
            className="search-bar-input"
            placeholder="Quicksearch (Order ID)"
            onKeyDown={handleInputChange}
            onChange={(event) => setInputValue(event.target.value)}
            aria-label="SearchBar to filter By order Id"
          />
        </InputGroup>
        <Form.Select
          className="input-group-time"
          id="input-group-time"
          value={filterData.find((f) => f.property === 'Date')?.key || ''}
          onChange={(event) => {
            handleTimeSelect(event.target.value);
          }}
          aria-label="Date"
        >
          <>
            <option value={''}>Date</option>
            {times?.map((time) => (
              <option key={time} value={time}>
                {getFilterLabel(time)}
              </option>
            ))}
          </>
        </Form.Select>
        <Button
          className="refresh-button"
          disabled={isLoading}
          onClick={handleRefreshOrder}
        >
          <ArrowClockwise /> Refresh orders
        </Button>
      </div>
      <ListGroup horizontal className="filters">
        {filterData.map((filter) => (
          <ListGroup.Item key={filter.key}>
            <Badge className="filter-badge">
              {filter.property}: {filter.label}{' '}
              <XCircle onClick={handleDelete(filter)} />
            </Badge>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TableOrderFilter;
