import React from 'react';

import './ProductModalHeader.scss';

const DEFAULT_SUBTITLE =
  'Select a product type from our catalog to start designing';

interface OptionalProps {
  centered?: boolean;
  subtitle?: string;
  inputDefaultValue?: string;
  showInput?: boolean;
  inputName?: string;
  handleInputChange?: (key: string) => void;
}

interface RequiredProps {
  title: string;
}

type ProductModalHeaderProps = RequiredProps & Partial<OptionalProps>;

export const ProductModalHeader: React.FC<ProductModalHeaderProps> = ({
  title,
  subtitle,
  inputName,
  inputDefaultValue,
  handleInputChange,
  showInput = true,
}) => {
  return (
    <div className="ProductModalHeader">
      <h5>{title}</h5>
      <p className="ProductModalHeader__Subtitle">
        {subtitle || DEFAULT_SUBTITLE}
      </p>

      {showInput && handleInputChange && (
        <div className="ProductForm">
          <div className="ProductForm__FormGroup">
            <label
              className="ProductModalHeader__InputTitle"
              htmlFor="ProductModalHeader__InputName"
            >
              {inputName}
            </label>
            <input
              id="ProductModalHeader__InputName"
              type="text"
              onChange={(event) => handleInputChange(event.target.value)}
              defaultValue={inputDefaultValue}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductModalHeader;
