import Icon from '../../Icons/Icon';
import './ScalingAlert.scss';
const ScalingAlert = () => {
  return (
    <div className="scaling-alert">
      <div className="scaling-alert__content">
        <div className="scaling-alert__icon">
          <Icon name="scaleIcon" />
        </div>
        <div className="scaling-alert__text">
          <h2>You are scaling your art</h2>
          <p>
            to edit your slots <br /> please apply your changes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScalingAlert;
