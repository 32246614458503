import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {IconProps} from './IconMapping';
import Icon from './Icon';

interface IconTooltipProps extends IconProps {
  tooltipText: string;
}

const IconTooltip: React.FC<IconTooltipProps> = ({
  tooltipText,
  name,
  ...rest
}) => {
  if (tooltipText) {
    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="auto"
        overlay={
          <Tooltip className="icon-tooltip" id={`tooltip-${name}`}>
            {tooltipText}
          </Tooltip>
        }
      >
        <span>
          <Icon name={name} {...rest} />
        </span>
      </OverlayTrigger>
    );
  }

  return <Icon name={name} {...rest} />;
};

export default IconTooltip;
