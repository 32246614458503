import React from 'react';
import {useAppSelector} from '../../../app/hooks';
import {selectShop} from '../../../features/shops/selectedShop';
import CustomModal from '../../CustomModal';
import './DuplicateNewProduct.scss';
import DuplicateForm from './DuplicateForms/DuplicateForms';
import {
  IColor,
  ISize,
  ISlotTemplateCanvas,
  ProductTypeDBModel,
  ProductTypeRequest,
} from '@gfxco/contracts';
import {getProductTypes} from '../../../api';
import {IDuplicateFormValues} from './DuplicateTypes';
import templateApi from '../../../api/templateApi';
import {GFXToastLaunch} from '../../ToastMessage/ToastMessage';
import LoadingModalWithTips from '../../LoadingModal/LoadingModalWithTips';
import Icon from '../../Icons/Icon';

type DuplicateNewProductProps = {
  visible: boolean;
  onClose: () => void;
  template: ISlotTemplateCanvas | null;
};

const DuplicateNewProduct: React.FC<DuplicateNewProductProps> = (props) => {
  const selectedShop = useAppSelector(selectShop);
  const shopId = selectedShop!.id;
  const defaultValuesForm = {
    name: props.template?.name || '',
    garmentType: '',
    printer: '',
    skuInfo: {
      description: '',
      printer: '',
    },
  };
  const [currentStep, setCurrentStep] = React.useState(1);
  const [productList, setProductList] = React.useState<ProductTypeDBModel[]>(
    [],
  );
  const [duplicatingStatus, setDuplicatingStatus] = React.useState('idle');
  const [newTemplateId, setNewTemplateId] = React.useState<string | null>(null);
  const [isFramedPoster, setIsFramedPoster] = React.useState(false);
  const [formValues, setFormValues] =
    React.useState<IDuplicateFormValues>(defaultValuesForm);
  const template = props.template;
  const designHasFrontAndBack = template?.hasFront && template?.hasBack;
  const shouldShowFramedStep = isFramedPoster && designHasFrontAndBack;

  React.useEffect(() => {
    if (props.visible) {
      getProductTypesData();
    }
    return () => {
      setCurrentStep(1);
      setFormValues(defaultValuesForm);
      setIsFramedPoster(false);
    };
  }, [props.visible]);

  const onSetValues = (key: string, value: string) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const getProductTypesData = async () => {
    try {
      if (!shopId) return;
      const params: ProductTypeRequest = {shopId};
      const productTypes = await getProductTypes(params);
      if (productTypes) {
        setProductList(productTypes);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const stepsParams = {
    formValues,
    setValue: onSetValues,
    templateName: template?.name,
    productList,
    templateCanvas: template! as ISlotTemplateCanvas,
  };

  const Steps = [
    {
      component: <DuplicateForm.FirstStepComponent {...stepsParams} />,
      buttonText: 'Select Your Printer',
    },
    {
      component: <DuplicateForm.SecondStepComponent {...stepsParams} />,
      buttonText: !shouldShowFramedStep ? 'Edit design' : 'Select your design',
    },
    {
      component: <DuplicateForm.FramedPosterFormComponent {...stepsParams} />,
      buttonText: 'Edit design',
    },
  ];

  const onNextStep = async () => {
    if (
      (!shouldShowFramedStep && currentStep === 2) ||
      (shouldShowFramedStep && currentStep === 3)
    ) {
      return await dispatchDuplicate();
    }

    if (currentStep === 1 && formValues.garmentType === 'framedPoster') {
      GFXToastLaunch(
        "Your linked slots won't be available on frame posters",
        2000,
        {
          parentContainerId: 'duplicate-new-product',
          showIcon: true,
          alertType: 'info',
          showAt: 'bottom',
        },
      );
      setIsFramedPoster(true);
    }

    setCurrentStep(currentStep + 1);
  };

  const onPreviousStep = () => {
    if (currentStep === 1) return;
    setCurrentStep(currentStep - 1);
  };

  const checkDisableButton = () => {
    if (currentStep === 1) {
      return !formValues.garmentType;
    }
    if (currentStep === 2) {
      return !formValues.skuInfo.description || !formValues.skuInfo.printer;
    }
    return false;
  };

  const dispatchDuplicate = async () => {
    if (!template) return;

    try {
      setDuplicatingStatus('loading');
      const result = await templateApi.duplicateTemplateNewGarment(
        template?.id,
        formValues.garmentType,
        {
          name: formValues.name || `Copy of ${template.name}`,
          side: formValues.sideToClone,
          manufacturerId: formValues.skuInfo.manufacturerId,
          skus: {
            colors: formValues.skuInfo.colors as IColor[],
            sizes: formValues.skuInfo.sizes as ISize[],
            printer: formValues.skuInfo.printer as string,
          },
        },
      );
      setDuplicatingStatus('success');
      if (result?.templateId) {
        setNewTemplateId(`${result.templateId}`);
        setFormValues(defaultValuesForm);
      }
    } catch (error) {
      console.error(error);
      setDuplicatingStatus('error');
      GFXToastLaunch('Error duplicating template, please try again', 5000, {
        alertType: 'danger',
        showAt: 'bottom',
        showIcon: true,
      });
      setFormValues(defaultValuesForm);
      props.onClose();
    }
  };

  if (duplicatingStatus !== 'idle') {
    const tips = [
      <div key={0} className="loading-designer-tip">
        <h2>
          <span>Your SKU may change through this process</span>
        </h2>
        <div className="tip-description">
          Update the available <span className="highlight-tip">colors</span> and{' '}
          <span className="highlight-tip">sizes</span> of your product before
          making any design edits.
        </div>
      </div>,
      <div key={1} className="loading-designer-tip">
        <h2>
          Designer tip: <span>If the printable area has changed</span>
        </h2>
        <div className="tip-description">
          Utilize the <Icon name="scaleIcon" width={20} height={20} />{' '}
          <span className="highlight-tip">scale tool</span> to proportionally
          resize your design(s) on the duplicated product.
        </div>
      </div>,
    ];

    return (
      <LoadingModalWithTips
        loading={duplicatingStatus === 'loading'}
        showSuccess={duplicatingStatus === 'success'}
        title={
          duplicatingStatus === 'loading'
            ? 'Duplicating template'
            : 'Template duplicated'
        }
        description="We're preparing the new showcase for your designs."
        tips={tips}
        redirectUrl={`/designs/edit/${newTemplateId}`}
      />
    );
  }

  const currentStepObject = Steps[currentStep - 1];
  return (
    <CustomModal
      modalTile=""
      className="duplicate-new-product-modal"
      variant="default"
      sizeType="middle-page"
      show={props.visible}
    >
      <div className="duplicate-new-product-content" id="duplicate-new-product">
        <div className="close-modal-button">
          <p onClick={props.onClose}>X</p>
        </div>
        <div className="summary-view">
          <div className="summary-view__header">
            <h3 className="summary-view__title">Copy on a new template</h3>
          </div>
          <div className="summary-view__content">
            <h4>Selected product: </h4>
            <p>{template?.name}</p>
            <img
              src={`${template?.proofFront}?v=${Date.now()}`}
              alt="product"
            />
            <div className="summary-view__content__change-product">
              <span onClick={props.onClose}> Change Product </span>
            </div>
          </div>
        </div>
        <div className="duplicate-form">
          <div className="duplicate-form__content">
            {currentStepObject?.component}
          </div>
          <div className="duplicate-form__navigators">
            <button className="btn btn-secondary" onClick={onPreviousStep}>
              {' '}
              {'< '} Back{' '}
            </button>
            <button
              className="btn btn-primary"
              onClick={onNextStep}
              disabled={checkDisableButton()}
            >
              {currentStepObject?.buttonText}
            </button>
          </div>
        </div>
      </div>
      <div className="footer-new-product">
        <p>
          <span>Designer Tip: </span> When you select a new product type, the
          printable area may change, take your time to scale each item.
        </p>
      </div>
    </CustomModal>
  );
};

export default DuplicateNewProduct;
