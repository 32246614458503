import React from 'react';
import {
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
  useNavigate,
} from 'react-router-dom';
import GFXButton from '../GFXButton';
import Loading from '../Loading';
import StepsComponent from '../Modal/StepModal/Steps';
import Icon from '../Icons/Icon';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';
import CloseConfirmationModal from '../CloseConfirmationModal';

// Hooks
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

// CSS
import './StepBuilder.scss';
import {useGFXInstance} from '@best-apps/gfx-editor';

type Steps = {
  name: string;
  component: React.ReactNode;
};

interface RequiredProps {
  isSaveEnabled: boolean;
  steps: Steps[];
  templateBuilder: React.ReactNode;
  selectedStepNumber: number;
  setStep: (step: number) => void;
}

interface OptionalProps {
  onClickBack?: () => void;
  onClickSave?: () => void;
  isProgressWarningEnabled?: boolean;
  isLoading?: boolean;
  onClickNext?: () => void;
  showNextButton?: boolean;
  showSaveButton?: boolean;
  isNextEnabled?: boolean;
}

type StepBuilderProps = RequiredProps & OptionalProps;

const StepBuilder: React.FC<StepBuilderProps> = (props) => {
  const {
    steps,
    templateBuilder,
    selectedStepNumber,
    onClickSave,
    isSaveEnabled,
    isProgressWarningEnabled = false,
    isLoading = false,
    setStep,
  } = props;
  const [initialShopId, setInitialShopId] = React.useState<number | null>(null);
  const gfx = useGFXInstance();
  const groupMode = gfx?.state.groupMode;
  const selectedShop = useAppSelector(selectShop);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (selectedShop) {
      if (initialShopId === null) {
        setInitialShopId(selectedShop.id);
      } else if (initialShopId !== selectedShop.id) {
        navigate('/designs');
      }
    }
  }, [selectedShop?.id]);

  const [saveStatus, setSaveStatus] = React.useState('iddle');

  const blockerFunction: BlockerFunction = (event) => {
    if (event.currentLocation.pathname === event.nextLocation.pathname) {
      return false;
    }

    if (saveStatus === 'loading' || saveStatus === 'success') {
      return false;
    }

    if (selectedShop && initialShopId !== selectedShop.id) {
      return false;
    }

    return isProgressWarningEnabled;
  };

  const blocker = useBlocker(
    React.useCallback(blockerFunction, [
      isProgressWarningEnabled,
      saveStatus,
      selectedShop?.id,
    ]),
  );

  const handleSaveButtonClick = async () => {
    if (onClickSave && isSaveEnabled && !groupMode) {
      setSaveStatus('loading');
      try {
        await onClickSave();
        setSaveStatus('success');
      } catch (error) {
        setSaveStatus('errored');
        GFXToastLaunch(
          'An error occurred saving your design, please try again',
          2000,
          {
            showAt: 'top',
            right: '3.5rem',
            top: '7rem',
            showIcon: true,
            alertType: 'danger',
          },
        );
        console.error('Error saving', error);
      }
    }
  };

  const selectedStep = steps[selectedStepNumber - 1];

  return (
    <>
      <div id="StepBuilder">
        <div className="builder-steps-container">
          <div className="builder-steps-container__content">
            {steps.map((step, index) => {
              const isSelected = index <= selectedStepNumber - 1;
              return (
                <StepsComponent
                  completionPercentage={steps.length / (index + 1)}
                  renderLine={index < selectedStepNumber - 1}
                  key={step.name}
                  isSelected={isSelected}
                  index={index}
                  step={step}
                  setStep={setStep}
                />
              );
            })}
          </div>
          {onClickSave && (
            <GFXButton
              variant="gfx-light-shadow"
              className="step-modal-save-button"
              disabled={!isSaveEnabled || saveStatus === 'loading'}
              onClick={handleSaveButtonClick}
            >
              <Icon name="save" fillColor={{default: '#4573BB'}} />
              {saveStatus === 'loading'
                ? 'Loading...'
                : 'Save and close this design'}
            </GFXButton>
          )}
        </div>
        <div
          className={`builder-content ${
            selectedStepNumber >= 4 ? 'builder-content-flex' : ''
          }`}
        >
          {isLoading ? (
            <div className="modal-loading">
              <Loading text="Loading design data" />
            </div>
          ) : (
            <>
              {
                <div
                  className={
                    selectedStepNumber >= 4
                      ? 'template-builder-container'
                      : 'template-builder-container--hide'
                  }
                >
                  {templateBuilder}
                </div>
              }
              <div className="configure-product-design-container">
                {selectedStep && selectedStep.component}
              </div>
            </>
          )}
        </div>

        <CloseConfirmationModal
          show={blocker.state === 'blocked'}
          onClose={blocker.proceed}
          onContinue={blocker.reset}
        />
      </div>
    </>
  );
};

export default StepBuilder;
