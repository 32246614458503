import Icon from '../../components/Icons/Icon';
import PrinterCollapsePanel from './PrinterCollapsePanel';
import './PrintersPage.scss';
import PrinterForm from './PrinterForm';
import WebhookForm from './WebhookForm';
import {
  ELoadingStates,
  IPrinterIntegrationFormValues,
  IWebhookIntegrationFormValues,
} from '@gfxco/contracts';
import PrinterTitle from './PrinterTitle';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {
  connectFifthSunIntegrationAsync,
  connectPrintfulIntegrationAsync,
  connectSendArtsIntegrationAsync,
  getFifthSunIntegrationStatusAsync,
  getPrintfulIntegrationStatusAsync,
  getSendArtsIntegrationStatusAsync,
  IPrinterIntegration,
  IWebhookIntegration,
  selectIntegrationStatusByPrinter,
} from '../../features/integrations/loadPrintersIntegration';
import React from 'react';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';
import Skeleton from 'react-loading-skeleton';
import {testSendArtsWebhook} from '../../api';

const PrintersPage = () => {
  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  if (!shopSelected) return <></>;
  const shopId = shopSelected?.id;

  const printfulIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'printful'),
  ) as IPrinterIntegration;

  const fifthSunIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'fifthSun'),
  ) as IPrinterIntegration;

  const sendArtsIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'sendArts'),
  ) as IWebhookIntegration;

  React.useEffect(() => {
    if (printfulIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getPrintfulIntegrationStatusAsync(shopId));
    }
    if (printfulIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting Printful integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (fifthSunIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getFifthSunIntegrationStatusAsync(shopId));
    }
    if (fifthSunIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting FifthSun integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [fifthSunIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (sendArtsIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getSendArtsIntegrationStatusAsync(shopId));
    }
    if (sendArtsIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting Send Arts integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [sendArtsIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (printfulIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(printfulIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.connectLoadStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (printfulIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(printfulIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.connectLoadStatus, dispatch]);

  React.useEffect(() => {
    if (fifthSunIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(fifthSunIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [fifthSunIntegrationStatus.connectLoadStatus, dispatch]);

  React.useEffect(() => {
    if (sendArtsIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(sendArtsIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [sendArtsIntegrationStatus.connectLoadStatus, dispatch]);

  const handlePrintfulFormSubmit = (params: IPrinterIntegrationFormValues) => {
    dispatch(connectPrintfulIntegrationAsync({shopId, ...params}));
  };

  const handleFifthFormSubmit = (params: IPrinterIntegrationFormValues) => {
    dispatch(connectFifthSunIntegrationAsync({shopId, ...params}));
  };

  const handleRenderArtFormSubmit = (params: IWebhookIntegrationFormValues) => {
    dispatch(connectSendArtsIntegrationAsync({shopId, ...params}));
  };

  const contentLoading =
    printfulIntegrationStatus.fetchStatus === ELoadingStates.LOADING ||
    fifthSunIntegrationStatus.fetchStatus === ELoadingStates.LOADING ||
    sendArtsIntegrationStatus.fetchStatus === ELoadingStates.LOADING;

  const isFullfilmentOptionNotConnected = !(
    printfulIntegrationStatus.connected || fifthSunIntegrationStatus.connected
  );

  return (
    <div id="PrintersPage" className="PrintersPage">
      <header className="PrintersPage__header">
        <div className="page-header">
          <div className="page-header__title">
            <h3>Print & Fulfillment</h3>
            <p>
              Set up your webhook or connect your printer to manage the
              fulfillment of your orders
            </p>
          </div>
        </div>
      </header>
      {contentLoading ? (
        <Skeleton
          className="CollapsePanel"
          containerClassName="PrintersPage__content"
          count={3}
          inline
        />
      ) : (
        <section className="PrintersPage__content">
          <div className="printer-info">
            <span className="printer-item-title">Printer Configuration:</span>
            <div className="printer-item">
              {printfulIntegrationStatus &&
                (printfulIntegrationStatus.connected ||
                  isFullfilmentOptionNotConnected) && (
                  <PrinterCollapsePanel
                    title={
                      <PrinterTitle
                        icon="samplePortal"
                        title="Printful"
                        description="Connect to your printful account, where they will automatically fulfill and ship your products."
                        tooltipId="printfulTooltip"
                      />
                    }
                    description="On-demand printing and fulfillment."
                    open
                    connected={printfulIntegrationStatus.connected}
                  >
                    <PrinterForm
                      id="printful"
                      connected={printfulIntegrationStatus.connected}
                      apiKey={printfulIntegrationStatus.apiKey}
                      storeId={printfulIntegrationStatus.storeId}
                      image={<Icon name="printfulLogo" />}
                      onSubmitted={handlePrintfulFormSubmit}
                      loading={
                        printfulIntegrationStatus.connectLoadStatus ===
                        ELoadingStates.LOADING
                      }
                      disabled={sendArtsIntegrationStatus.connected}
                      learnHowUrl="https://gfx-help.notion.site/Printful-setup-dc90a3e8e0cf46b8ab53434a4bca0d1a"
                    />
                  </PrinterCollapsePanel>
                )}
              {fifthSunIntegrationStatus &&
                fifthSunIntegrationStatus.connected && (
                  <PrinterCollapsePanel
                    title={
                      <PrinterTitle
                        icon="samplePortal"
                        title="Fifth Sun"
                        description="Fifthsun.com is a private label apparel which produces unique clothing for companies"
                        tooltipId="fifthSunTooltip"
                      />
                    }
                    description="Products, printing, branding, and fulfillment by connecting your store."
                    connected={fifthSunIntegrationStatus.connected}
                  >
                    <PrinterForm
                      id="fifthSun"
                      connected={fifthSunIntegrationStatus.connected}
                      apiKey={fifthSunIntegrationStatus.apiKey}
                      storeId={fifthSunIntegrationStatus.storeId}
                      image={<Icon name="fifthSunLogo" />}
                      onSubmitted={handleFifthFormSubmit}
                      loading={
                        fifthSunIntegrationStatus.connectLoadStatus ===
                        ELoadingStates.LOADING
                      }
                      disabled={sendArtsIntegrationStatus.connected}
                    />
                  </PrinterCollapsePanel>
                )}
            </div>
          </div>
          <div className="printer-info">
            <span className="printer-item-title">Render & Export Art:</span>
            <div className="printer-item">
              <PrinterCollapsePanel
                title={
                  <PrinterTitle
                    icon="sendArts"
                    title="Render Art"
                    description="Generate your artwork in any size you want, connect it to your printer via a webhook"
                    tooltipId="renderArtTooltip"
                  />
                }
                description="Generate your own art and export it through a webhook. No external fulfillment required."
                connected={sendArtsIntegrationStatus.connected}
              >
                <WebhookForm
                  id="renderArt"
                  connected={sendArtsIntegrationStatus.connected}
                  webhookUrl={sendArtsIntegrationStatus.webhookUrl}
                  onSubmitted={handleRenderArtFormSubmit}
                  onTestWebhook={testSendArtsWebhook}
                  loading={
                    sendArtsIntegrationStatus.connectLoadStatus ===
                    ELoadingStates.LOADING
                  }
                  disabled={
                    fifthSunIntegrationStatus.connected ||
                    printfulIntegrationStatus.connected
                  }
                  learnHowUrl="https://gfx-help.notion.site/External-Orders-API-Documentation-7f0b4c350f40478ba2f97cb5b0a41938#1383415976e041f388e77b415103aca3"
                  onDisconnected={() =>
                    handleRenderArtFormSubmit({webhookUrl: null})
                  }
                />
              </PrinterCollapsePanel>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PrintersPage;
