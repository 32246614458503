import React from 'react';

import './GFXCheckbox.scss';

interface GFXCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  label?: string;
  id: string;
}

const GFXCheckbox: React.FC<GFXCheckboxProps> = (props) => {
  const {inputClassName, className, label, id, ...rest} = props;

  let labelClass = 'gfx-checkbox-label';
  if (rest.disabled) {
    labelClass += ' disabled';
  }
  return (
    <div className={`gfx-checkbox-container ${className}`}>
      <input
        {...rest}
        id={id}
        className={props.inputClassName || 'gfx-checkbox'}
        {...rest}
        type="checkbox"
      />
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default GFXCheckbox;
