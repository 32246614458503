import React, {useState, useEffect, useRef} from 'react';
import {SESSION_STORAGE_KEYS} from '../../../constants';

// Components
import Icon from '../../Icons/Icon';

// hooks
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {
  selectShop,
  updateSelectedShop,
} from '../../../features/shops/selectedShop';
import {selectShops} from '../../../features/shops/loadShops';
import {useNavigate} from 'react-router-dom';
import useSessionStorage from '../../../hooks/useSessionStorage';

import './GFXSelectStoreDropdown.scss';
import {GFXToastLaunch} from '../../ToastMessage/ToastMessage';
import {selectUserPermissions} from '../../../features/user';
import classNames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const {REACT_APP_HELP_CENTER_URL: HelpCenterURL = ''} = process.env;
const WHITE_HEX_COLOR = '#FFFFFF';
const LIGHT_BLUE_HEX_COLOR = '#B8F2FF';

interface GFXDropdownItemRequiredProps {
  name: string;
}

interface GFXDropdownItemOptionalProps {
  iconPrefix?: React.ReactNode;
  tooltip?: string | null;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
  ) => void;
  additionalClasses?: string;
}

type GFXDropdownItemProps = GFXDropdownItemRequiredProps &
  GFXDropdownItemOptionalProps;

const GFXDropdownItem: React.FC<GFXDropdownItemProps> = ({
  name,
  iconPrefix,
  onClick,
  tooltip = '',
  additionalClasses = '',
}) => {
  if (!tooltip) {
    return (
      <div
        className={`gfx-dropdown-item ${additionalClasses}`}
        onClick={onClick}
      >
        <span style={{display: 'flex'}}>
          {iconPrefix} <p className="gfx-ellipsis">{name}</p>
        </span>
      </div>
    );
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="auto"
      overlay={
        <Tooltip className="icon-tooltip" id={`tooltip-${name}`}>
          {tooltip}
        </Tooltip>
      }
    >
      <div
        className={`gfx-dropdown-item ${additionalClasses}`}
        onClick={onClick}
      >
        <span style={{display: 'flex'}}>
          {iconPrefix} <p className="gfx-ellipsis">{name}</p>
        </span>
      </div>
    </OverlayTrigger>
  );
};

interface GFXDropdownItemSubListRequiredProps {
  children: React.ReactNode;
  name: string;
}

interface GFXDropdownItemSubListOptionalProps {
  position?: 'left' | 'right';
}

type GFXDropdownItemSubListProps = GFXDropdownItemSubListRequiredProps &
  GFXDropdownItemSubListOptionalProps;

const GFXDropdownItemSubList: React.FC<GFXDropdownItemSubListProps> = ({
  children,
  name,
  position = 'left',
}) => {
  const [showSubList, setShowSubList] = useState(false);
  const subListRef = useRef<HTMLDivElement>(null);

  const handleSubListToggle = () => {
    setShowSubList(!showSubList);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      subListRef.current &&
      !subListRef.current.contains(event.target as Node)
    ) {
      setShowSubList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={subListRef}
      className={`gfx-dropdown-item`}
      onClick={handleSubListToggle}
    >
      {name}

      {showSubList && (
        <div
          className={`dropdown-sub-menu gfx-list-position-${position}`}
          onClick={handleSubListToggle}
        >
          {children}
        </div>
      )}
    </div>
  );
};

interface GFXSelectStoreAlertMessageProps {
  currentStore: string;
  newStore: string;
}
const GFXSelectStoreAlertMessage: React.FC<GFXSelectStoreAlertMessageProps> = ({
  currentStore,
  newStore,
}) => {
  return (
    <div id="gfx-select-store-alert-message">
      <p>
        <span className="success-text">You switched store</span>{' '}
        {`from "${currentStore}" to "${newStore}"`}{' '}
        <span className="success-text">successfully</span>
      </p>
    </div>
  );
};

export const GFXSelectStoreDropdown: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const userPermissions = useAppSelector(selectUserPermissions) || [];
  const allowedPermissions = ['*', 'MYSTORE'];
  const userHasPermission = userPermissions.some((permission) =>
    allowedPermissions.includes(permission),
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {setValue} = useSessionStorage();

  const shopSelected = useAppSelector(selectShop);
  const shops = useAppSelector(selectShops);
  const handleDropdownToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (shopSelected) {
    return (
      <div id="gfx-select-store-dropdown" ref={dropdownRef}>
        <span className="gfx-select-store-span">Selected Store:</span>
        <div className="gfx-dropdown">
          <button
            className="gfx-dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            onClick={handleDropdownToggle}
            style={{
              backgroundColor: shopSelected?.hex_color ?? '#4573bb',
              color:
                shopSelected?.hex_color === WHITE_HEX_COLOR
                  ? '#838383'
                  : shopSelected?.hex_color === LIGHT_BLUE_HEX_COLOR
                  ? '#4573BB'
                  : WHITE_HEX_COLOR,
            }}
          >
            <Icon
              name="homeFillWhite"
              classes="gfx-dropdown-home-icon"
              fillColor={{
                default:
                  shopSelected?.hex_color === WHITE_HEX_COLOR
                    ? '#838383'
                    : shopSelected?.hex_color === LIGHT_BLUE_HEX_COLOR
                    ? '#4573BB'
                    : WHITE_HEX_COLOR,
              }}
            />
            <span className="gfx-store-span gfx-ellipsis">
              {shopSelected?.name}
            </span>
            <Icon
              name="downArrowWhite"
              classes="gfx-dropdown-arrow-down-icon"
              fillColor={{
                default:
                  shopSelected?.hex_color === WHITE_HEX_COLOR
                    ? '#838383'
                    : shopSelected?.hex_color === LIGHT_BLUE_HEX_COLOR
                    ? '#4573BB'
                    : WHITE_HEX_COLOR,
              }}
            />
          </button>
          {showMenu && (
            <div
              className="gfx-dropdown-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className="gfx-learn-more-link"
                target="_blank"
                href={HelpCenterURL}
                rel="noreferrer"
                onClick={() => {
                  handleDropdownToggle();
                }}
              >
                Learn more
              </a>
              <img
                src={`${shopSelected?.shop_image_url}?v=${Date.now()}`}
                alt="Store Image"
                className="gfx-store-image"
                loading="lazy"
              />
              <b className="gfx-store-name gfx-ellipsis">
                {shopSelected?.name}
              </b>
              <Icon name="separator" />
              <GFXDropdownItemSubList name="Change Store">
                {shops?.map((shop) => (
                  <GFXDropdownItem
                    name={shop.name}
                    iconPrefix={
                      shop.id === shopSelected.id && (
                        <Icon name="checkIconBlue" />
                      )
                    }
                    key={shop.id}
                    onClick={() => {
                      if (shop.id !== shopSelected.id) {
                        setValue(
                          SESSION_STORAGE_KEYS.SELECTED_SHOP,
                          shop.id.toString(),
                        );
                        dispatch(updateSelectedShop(shop));
                        navigate('/');
                        handleDropdownToggle();
                        GFXToastLaunch(
                          <GFXSelectStoreAlertMessage
                            currentStore={shopSelected.name}
                            newStore={shop.name}
                          />,
                          5000,
                          {
                            alertType: 'success',
                            showAt: 'top',
                            right: '1rem',
                            top: '3rem',
                            showIcon: true,
                            parentContainerId: 'gfx-select-store-dropdown',
                          },
                        );
                      }
                    }}
                  />
                ))}
              </GFXDropdownItemSubList>
              <GFXDropdownItem
                name="Store Settings"
                tooltip={
                  !userHasPermission
                    ? `You don't have permission to access store settings. Please contact the store owner to request access.`
                    : null
                }
                onClick={() => {
                  if (!userHasPermission) {
                    return;
                  }
                  navigate('/my-store');
                  handleDropdownToggle();
                }}
                additionalClasses={classNames('underline', {
                  'gfx-disabled': !userHasPermission,
                })}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default GFXSelectStoreDropdown;
