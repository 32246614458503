import React from 'react';
import {IDesignTemplate} from '@gfxco/contracts';
import IconTooltip from '../../Icons/IconTooltip';
import './PublishOption.scss';
import classNames from 'classnames';

type PublishOptionProps = {
  integrationConnected: boolean;
  design: IDesignTemplate;
  onPublish: (design: IDesignTemplate) => void;
};

const PublishOption: React.FC<PublishOptionProps> = ({
  integrationConnected,
  design,
  onPublish,
}) => {
  const handleOnClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onPublish(design);
  };

  return (
    <div
      className={classNames('publish-button', {
        'enable-publish-tooltip': integrationConnected,
      })}
    >
      <IconTooltip
        box
        fillColor={{default: '#45BB74', disabled: '#d6d6d6'}}
        disabled={!integrationConnected}
        name="publishIcon"
        onClick={handleOnClick}
        tooltipText={
          !integrationConnected
            ? 'Need to integrate with Shopify to enable this option.'
            : 'Publish'
        }
      />
    </div>
  );
};

export default PublishOption;
