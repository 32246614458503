import React from 'react';
import Select, {components, DropdownIndicatorProps} from 'react-select';
import {SortDown} from 'react-bootstrap-icons';

import './SortSelect.scss';

interface SortOption {
  label: string;
  value: string;
}

interface RequiredProps {
  handleSortChange: (selectedOption: any) => void;
  options: SortOption[];
  defaultValue: string;
}

interface OptionalProps {
  className?: string;
  showPrefix?: boolean;
}

type SortSelectProps = RequiredProps & OptionalProps;

const DropdownIndicator = (props: DropdownIndicatorProps<SortOption, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDown />
    </components.DropdownIndicator>
  );
};

const SortSelect: React.FC<SortSelectProps> = ({
  handleSortChange,
  options,
  defaultValue,
  className = '',
  showPrefix = false,
}) => {
  let defaultValueObj = options.find((o) => o.value === defaultValue);

  if (showPrefix && defaultValueObj)
    defaultValueObj = {
      ...defaultValueObj,
      label: `Sort by: ${defaultValueObj.label}`,
    };

  return (
    <Select
      id="SortSelect"
      className={['sort-select', className].join(' ')}
      components={{DropdownIndicator}}
      classNamePrefix="sort-select"
      defaultValue={defaultValueObj}
      onChange={handleSortChange}
      name="sort-select"
      options={options}
    />
  );
};

export default SortSelect;
