import React from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {IWebhookIntegrationFormValues} from '@gfxco/contracts';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';

import GFXTooltip from '../../../components/Tooltip/Tooltip';
import PrinterCollapsedContent from '../PrinterCollapseContent';
import Icon from '../../../components/Icons/Icon';
import Button from '../../../components/Button';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {
  selectShop,
  getShopConfigsAsync,
} from '../../../features/shops/selectedShop';

type WebhookFormProps = {
  id: string;
  webhookUrl?: string;
  loading?: boolean;
  connected?: boolean;
  image?: string | React.ReactNode;
  learnHowUrl?: string;
  disabled?: boolean;
  onSubmitted?: (params: IWebhookIntegrationFormValues) => void;
  onTestWebhook?: (webhookUrl: string) => Promise<boolean>;
  onDisconnected?: () => void;
};

const WebhookForm: React.FC<WebhookFormProps> = ({
  id,
  webhookUrl,
  loading = false,
  connected = false,
  image,
  learnHowUrl,
  disabled = false,
  onSubmitted,
  onTestWebhook,
  onDisconnected,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    getValues,
    setValue,
  } = useForm<IWebhookIntegrationFormValues>({});
  const [tested, setTested] = React.useState<boolean>(false);
  const [testErrored, setTestErrored] = React.useState<boolean>(false);
  const [testing, setTesting] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const [showDisconnectWarning, setShowDisconnectWarning] =
    React.useState<boolean>(false);

  const handleFormSubmit = (params: IWebhookIntegrationFormValues) => {
    setTested(false);
    setTestErrored(false);
    if (onSubmitted) {
      onSubmitted(params);
    }

    if (shopId) {
      dispatch(getShopConfigsAsync(shopId));
    }
  };

  const handleTestWebhook = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setTesting(true);
    if (onTestWebhook && getValues('webhookUrl')) {
      onTestWebhook(getValues('webhookUrl') || '')
        .then((result) => {
          setTested(result);
          setTestErrored(!result);
        })
        .catch(() => {
          setTested(false);
          setTestErrored(true);
        })
        .finally(() => {
          setTesting(false);
        });
    }
  };

  return (
    <PrinterCollapsedContent
      id={id}
      loading={loading || testing}
      connected={connected}
      image={image}
      tested={tested}
      errored={!!errors.webhookUrl || testErrored}
      type="webhook"
    >
      <form
        className="WebhookForm"
        onSubmit={!loading ? handleSubmit(handleFormSubmit) : undefined}
      >
        <Form.Group controlId="formWebhookURL">
          <Form.Label>Webhook URL*</Form.Label>
          <OverlayTrigger
            placement="right"
            delay={{show: 200, hide: 400}}
            overlay={
              <Tooltip id={`${id}-webhookUrlTooltip`}>
                Add the URL where you want to receive the arts files. See our
                documentation to learn more
              </Tooltip>
            }
          >
            <span className="d-inline-block tooltip-icon">
              <Icon name="information" />
            </span>
          </OverlayTrigger>
          {errors.webhookUrl?.type === 'required' && (
            <span className="form-error">Information required to continue</span>
          )}
          {errors.webhookUrl?.type === 'pattern' && (
            <span className="form-error">URL must be https</span>
          )}
          <Form.Control
            type="url"
            placeholder="https://gfx.webhook/123456"
            readOnly={connected}
            disabled={connected || disabled}
            autoComplete="off"
            defaultValue={webhookUrl}
            aria-invalid={errors.webhookUrl ? 'true' : 'false'}
            {...register('webhookUrl', {
              required: true,
              pattern: /^https:\/\/.*/,
            })}
          />
        </Form.Group>
        <div
          className={classNames({
            'connect-container': true,
            'connect-container--spaced': learnHowUrl,
          })}
        >
          {learnHowUrl && (
            <Button
              variant="link"
              href={learnHowUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="externalLink" /> Learn how to set up this webhook
            </Button>
          )}
          <div className="actions">
            <Button
              disabled={loading || connected || !isValid || testing}
              variant="secondary"
              onClick={handleTestWebhook}
            >
              {testing ? 'Testing' : 'Send Test'}
            </Button>
            {!connected && (
              <Button
                variant="primary"
                type="submit"
                disabled={loading || !isValid}
              >
                <Icon height={18} width={18} name={'samplePortal'} />
                {loading ? 'Loading…' : 'Connect'}
              </Button>
            )}

            {connected && (
              <Button
                variant="primary"
                disabled={loading}
                onClick={() =>
                  connected ? setShowDisconnectWarning(true) : undefined
                }
              >
                <Icon height={18} width={18} name={'samplePortal'} />
                {loading ? 'Loading…' : 'Disconnected'}
              </Button>
            )}
            {showDisconnectWarning && onDisconnected && (
              <GFXTooltip
                onAccept={() => {
                  onDisconnected();
                  setShowDisconnectWarning(false);
                  setValue('webhookUrl', '');
                }}
                onClose={() => setShowDisconnectWarning(false)}
                acceptText="Yes, disconnect"
                height="auto"
                top="auto"
                left="65%"
              >
                <div className="alert-message">
                  <span>
                    This action cannot be undone. Are you sure you want to
                    disconnect your webhook?
                  </span>
                </div>
              </GFXTooltip>
            )}
          </div>
        </div>
      </form>
    </PrinterCollapsedContent>
  );
};

export default WebhookForm;
