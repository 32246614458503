import React from 'react';
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {IShopifyIntegrationFormValues} from '@gfxco/contracts';

import Loading from '../Loading';

import {ReactComponent as EcommerceConnectionConnected} from './connected.svg';
import {ReactComponent as EcommerceConnectionDisconnected} from './disconnected.svg';
import {ReactComponent as ShopifyIcon} from './shopify.svg';
import {ReactComponent as EcommerceIcon} from '../../assets/icons/ecommerce-icon.svg';
import {ReactComponent as InformationIcon} from '../../assets/icons/information-icon.svg';

import './ShopifyIntegrationForm.scss';

interface ShopifyIntegrationFormProps {
  connected: boolean;
  onConnect?: (params: IShopifyIntegrationFormValues) => Promise<void>;
  storeName?: string;
  apiKey?: string;
  apiSecretKey?: string;
  appPassword?: string;
}

export const ShopifyIntegrationForm: React.FC<ShopifyIntegrationFormProps> = ({
  connected,
  onConnect,
  storeName = '',
  apiKey = '',
  apiSecretKey = '',
  appPassword = '',
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const {register, handleSubmit, reset} =
    useForm<IShopifyIntegrationFormValues>({});

  const handleFormSubmit = (params: IShopifyIntegrationFormValues) => {
    setLoading(true);
    if (onConnect) {
      onConnect(params)
        .then(() => {
          reset();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const DefaultFragmentStatus = () => (
    <div className="integration-status-box">
      <span>Looks like nothing is connected to your store yet </span>
      <EcommerceConnectionDisconnected />
    </div>
  );

  const ConnectedFragmentStatus = () => (
    <div className="integration-status-box integration-status-box--connected">
      <span>
        Your store has been <b>connected</b> successfully
      </span>

      <EcommerceConnectionConnected />
    </div>
  );

  const LoadingFragmentStatus = () => (
    <div className="integration-status-box">
      <Loading variant="primary" spinnerOnly />
    </div>
  );

  return (
    <div id="ShopifyIntegrationForm" className="shopify-container">
      <Form
        className="shopify-container__form"
        onSubmit={!isLoading ? handleSubmit(handleFormSubmit) : undefined}
      >
        <header>
          <EcommerceIcon />
          <h3>E-commerce Connection:</h3>
          <OverlayTrigger
            placement="right"
            delay={{show: 200, hide: 400}}
            overlay={
              <Tooltip id="shopify-integration-tooltip">
                Connect your store with your e-commerce platform.
              </Tooltip>
            }
          >
            <InformationIcon className="info" />
          </OverlayTrigger>
        </header>
        <div className="form-group-container">
          <Form.Group controlId="formStoreName">
            <Form.Label>Store Domain*</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Your Store Name"
                readOnly={connected}
                disabled={connected}
                autoComplete="off"
                defaultValue={storeName}
                {...register('storeName', {required: true})}
              />
              <InputGroup.Text>.myshopify.com</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formApiKey">
            <Form.Label>API Key*</Form.Label>
            <Form.Control
              type="text"
              placeholder="f8a242e9bfe5c3a"
              readOnly={connected}
              disabled={connected}
              autoComplete="off"
              defaultValue={apiKey}
              {...register('apiKey', {required: true})}
            />
          </Form.Group>

          <Form.Group controlId="formApiSecretKey">
            <Form.Label>API secret key*</Form.Label>
            <Form.Control
              type={connected ? 'text' : 'password'}
              placeholder="2eeb9ba9294174de"
              readOnly={connected}
              disabled={connected}
              autoComplete="shopify-integration api-secret-key"
              defaultValue={apiSecretKey}
              {...register('apiSecretKey', {required: true})}
            />
          </Form.Group>

          <Form.Group controlId="formAppPassword">
            <Form.Label>Admin API access token*</Form.Label>
            <Form.Control
              type={connected ? 'text' : 'password'}
              placeholder="shppa_82da9d55ec"
              readOnly={connected}
              disabled={connected}
              autoComplete="shopify-integration app-password"
              defaultValue={appPassword}
              {...register('appPassword', {required: true})}
            />
          </Form.Group>
        </div>
        <div className="connect-container">
          <Button
            variant="primary"
            type="submit"
            disabled={isLoading || connected}
          >
            <ShopifyIcon />
            {isLoading ? 'Loading…' : 'Connect'}
          </Button>
        </div>
      </Form>

      <div className="shopify-container__status">
        {isLoading ? (
          <LoadingFragmentStatus />
        ) : connected ? (
          <ConnectedFragmentStatus />
        ) : (
          <DefaultFragmentStatus />
        )}
      </div>
    </div>
  );
};

export default ShopifyIntegrationForm;
