import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {Search} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import SortLabel from '../SortLabel';
import DesignsList from './DesignsList';
import useQueryParams from '../../hooks/useQueryParams';
import {
  ISlotTemplateCanvas,
  IDraftCanva,
  ELoadingStates,
} from '@gfxco/contracts';

import './DesignsContent.scss';
import {
  fetchStatusByPage,
  getDesignTemplatesAsync,
  getDesignTemplatesByPage,
  resetDesignTemplatesPages,
} from '../../features/designTemplates/loadDesignTemplates';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import usePagination from '../../hooks/usePagination';

interface RequiredProps {
  onEdit: (draftCanva: ISlotTemplateCanvas) => void;
  onEditDraft: (draftCanva: IDraftCanva) => void;
}

const DesignsContent: React.FC<RequiredProps> = () => {
  const [queryParams, setQueryParams] = useQueryParams();
  const currentTab = queryParams.tab || 'all';
  const inputSearchRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const selectedShop = useAppSelector(selectShop);
  if (!selectedShop) return <></>;
  const shopId = selectedShop.id;
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const currentDesignFetchStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );
  const designs = useAppSelector((state) =>
    getDesignTemplatesByPage(state, currentPage),
  );

  const handleSortChange = (selectedOption: any) => {
    setQueryParams({...queryParams, sortBy: selectedOption.value});
  };

  const handleTabChange = (key: string | null) => {
    if (key) {
      setQueryParams({tab: key, sortBy: 'newest', search: ''});
      inputSearchRef.current!.value = '';
    }
  };

  const handleSearchChange = (search: string) => {
    setQueryParams({...queryParams, search});
  };

  const lookForDesigns = () => {
    dispatch(
      getDesignTemplatesAsync({
        shopId,
        offset,
        page: currentPage,
        limit,
        sortBy: queryParams.sortBy || 'newest',
        search: queryParams.search || '',
        type: queryParams.tab,
      }),
    );
  };

  React.useEffect(() => {
    if (currentDesignFetchStatus === ELoadingStates.IDLE) {
      lookForDesigns();
    }
  }, [selectedShop, currentDesignFetchStatus, offset, limit]);

  React.useEffect(() => {
    if (shopId) {
      lookForDesigns();
    }
  }, [shopId]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetDesignTemplatesPages());
  }, [queryParams.sortBy, queryParams.search, queryParams.tab]);

  React.useEffect(() => {
    const tab = queryParams.tab || 'all';
    const sortBy = queryParams.sortBy || 'newest';
    const search = queryParams.search || '';

    setQueryParams({...queryParams, tab, sortBy, search});
  }, []);

  return (
    <div id="DesignsContent">
      <div className="tabs-bg">
        <div>
          <Tabs
            className="tab-options"
            onSelect={handleTabChange}
            activeKey={currentTab}
          >
            <Tab className="tab-option" title="All elements" eventKey={'all'} />
            <Tab className="tab-option" title="Designs" eventKey={'designs'} />
            <Tab
              className="tab-option"
              title="Published"
              eventKey={'published'}
            />
          </Tabs>
        </div>
        <div className="flex-grow-1">
          <div className="list-toolbar">
            <InputGroup className="search-bar-group search-bar-design-templates">
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search design templates by name"
                onChange={debounce((event) => {
                  handleSearchChange(event.target.value);
                }, 500)}
                aria-label="SearchBar to filter by design templates name"
                ref={inputSearchRef}
                defaultValue={queryParams.search}
              />
            </InputGroup>
            <SortLabel
              handleSortChange={handleSortChange}
              className="select-sort-design-templates"
            />
          </div>
        </div>
      </div>
      <Row>
        <Col sm={12}>
          <DesignsList
            designs={designs}
            fetchStatus={currentDesignFetchStatus}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DesignsContent;
