import React from 'react';
import {ISlotTemplateCanvas} from '@gfxco/contracts';
import PublishOption from '../PublishOption';
import {Dropdown} from 'react-bootstrap';
import Icon from '../../Icons/Icon';
import {ThreeDots} from 'react-bootstrap-icons';
import GFXImage from '../../Image/Image';
import DeleteTemplate from '../../DeleteTemplate';
import {useNavigate} from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';

import './DesignItem.scss';
import classNames from 'classnames';
import {updatePublishStatus} from '../../../api';
import WarningTooltip from '../../WarningTooltip';

interface DesignItemProps {
  design: ISlotTemplateCanvas;
  imageSrc: string;
  onPublish: (design: ISlotTemplateCanvas) => void;
  onDuplicate: (design: ISlotTemplateCanvas) => void;
  onUpdateDesigns: () => void;
  integrationConnected: boolean;
}

const DesignItem: React.FC<DesignItemProps> = ({
  design,
  imageSrc,
  onPublish,
  onDuplicate,
  onUpdateDesigns,
  integrationConnected,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const [showStatusDescription, setShowStatusDescription] =
    React.useState(false);
  const [showDesignWarning, setShowDesignWarning] =
    React.useState<boolean>(false);

  const handleEditSlotControls = (designId: number) => {
    if (design.publishStatus === 'active') {
      return setShowDesignWarning(true);
    }

    navigate(`controls/${designId}`, {
      state: {params: queryParams},
    });
  };
  const [, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleEditDesign = (designId: number) => {
    return navigate(`/designs/edit/${designId}`);
  };

  const getDesignStatus = () => {
    if (design.publishStatus === 'draft') {
      return (
        <span className="design-status-text design-status-text--draft">
          <Icon
            name="edit"
            height={10}
            width={10}
            fillColor={{default: '#cbaf45'}}
          />
          Published - Draft
        </span>
      );
    }
    if (design.publishStatus === 'active') {
      return (
        <span className="design-status-text design-status-text--active">
          <Icon name="checkIcon" height={10} width={10} />
          Published - Active
        </span>
      );
    }

    return <span className="design-status-text">Design</span>;
  };

  const handleUpdateToDraft = async (id: number) => {
    try {
      setIsLoading(true);
      const product = await updatePublishStatus({
        templateId: id,
        status: 'draft',
      });
      navigate(`controls/${id}`, {
        state: {params: queryParams},
      });
      setTimeout(() => {
        if (product) window.open(product.url, '_blank', 'noreferrer');
      }, 2000);
    } catch (error) {
      setShowError(true);
    }
  };

  const Image = React.useMemo(
    () => (
      <GFXImage
        src={imageSrc}
        className="design-item-image"
        placeholderSrc="/loading-render.gif"
      />
    ),
    [imageSrc],
  );

  return (
    <div
      className={classNames('design-item', {
        'design-item--duplicated': design.duplicated,
      })}
      key={design.id}
      onClick={(event) => {
        event.stopPropagation();
        handleEditSlotControls(design.id);
      }}
    >
      <div
        className="design-item-status"
        onMouseEnter={() => setShowStatusDescription(true)}
        onMouseLeave={() => setShowStatusDescription(false)}
      >
        {getDesignStatus()}
      </div>
      <div className="design-item-menu">
        {!design.publishStatus && (
          <div className="design-menu-icon">
            <PublishOption
              design={design}
              onPublish={onPublish}
              integrationConnected={integrationConnected}
            />
          </div>
        )}
        <Dropdown
          className="design-menu-icon"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Dropdown.Toggle variant="outline-light">
            <ThreeDots />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!design.publishStatus && (
              <Dropdown.Item onClick={() => handleEditDesign(design.id)}>
                <Icon name="editDesign" /> Edit design
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => handleEditSlotControls(design.id)}>
              <Icon name="templateConfiguration" /> Template configuration
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onDuplicate(design)}>
              <Icon name="duplicate2" /> Duplicate
            </Dropdown.Item>
            {!design.publishStatus && (
              <DeleteTemplate
                templateId={design.id}
                updateTemplates={onUpdateDesigns}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {Image}
      <p className="design-item-name" title={design.name}>
        {design.name}
      </p>

      <div
        className={classNames('banner-status-description', {
          'banner-status-description--visible': showStatusDescription,
        })}
      >
        <p>
          {design.publishStatus === 'draft' && (
            <>
              In order to modify this design, we set the product as a{' '}
              <em>Published draft</em>, you can activate it after your changes
              are made on your template configuration.
            </>
          )}
          {design.publishStatus === 'active' && (
            <>
              This design is currently listed as an <em>active product</em> in
              your Shopify Store.
            </>
          )}
          {!design.publishStatus && (
            <>
              Design in your gfx admin, <em>Publish</em> this product to see it
              in your online store
            </>
          )}
        </p>
      </div>
      {showDesignWarning && (
        <WarningTooltip
          id={design.id}
          onClose={() => setShowDesignWarning(false)}
          isLoading={isLoading}
          onAccept={handleUpdateToDraft}
        />
      )}
      {design.duplicated && (
        <div className="duplicate-icon">
          <Icon name="badgeNewDesign" />
        </div>
      )}
    </div>
  );
};

export default DesignItem;
