import React from 'react';
import Form from 'react-bootstrap/Form';
import * as Contracts from '@gfxco/contracts';
import {
  currentAuthenticatedUser,
  updateUserAttributes,
} from '../../features/auth';
import {useAppDispatch} from '../../app/hooks';

import SectionHeader from '../../components/SectionHeader';
import Icon from '../../components/Icons/Icon';
import ProfileForm from './ProfileForm';
import AccountForm from './AccountForm';
import PasswordForm from './PasswordForm';
import ContentLayout from '../../components/ContentLayout';
import ContentMain from '../../components/ContentLayout/ContentMain';
import ContentSidebar from '../../components/ContentLayout/ContentSidebar';

import ProfileImage from '../../assets/icons/ProfileImage.svg';

import './AccountPage.scss';

interface GetUserOptions {
  bypassCache?: boolean;
}

interface AccountPageProps {}

const AccountPage: React.FunctionComponent<AccountPageProps> = () => {
  const [user, setUser] = React.useState<Contracts.ICognitoUser>(
    {} as Contracts.ICognitoUser,
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = async (options: GetUserOptions = {}) => {
    const {bypassCache} = options;

    const result = await dispatch(
      currentAuthenticatedUser({bypassCache}),
    ).unwrap();

    setUser(result.user);
  };

  const updateUser = async (
    updatedAttributes: Contracts.CognitoUserAttributes,
  ) => {
    await dispatch(updateUserAttributes({user, updatedAttributes})).unwrap();
    await getUser({bypassCache: true});
  };

  const getInitials = () => {
    const firstName = user?.attributes?.given_name;
    const lastName = user?.attributes?.family_name;
    const username = user?.username;
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`;
    }
    if (username) {
      return username[0];
    }

    return '-';
  };

  return (
    <div id="AccountPage">
      <SectionHeader
        title="Account"
        subtitle="Edit your Account Configuration"
        tabs={[{key: 1, name: 'User Settings', path: '/accounts'}]}
      />
      <ContentLayout>
        <ContentSidebar>
          <h2 className="section-title">Profile settings</h2>
          <img
            className="profile-image"
            src={`https://placehold.co/144/eef7ff/4573BB/png?text=${getInitials()}`}
            alt="Account Profile Image"
            onError={({currentTarget}) => {
              currentTarget.onerror = null;
              currentTarget.src = ProfileImage;
            }}
          />
          <ProfileForm user={user} updateUser={updateUser} />
        </ContentSidebar>
        <ContentMain>
          <div className="content-section account-username">
            <h2 className="section-title">Your gfx User</h2>
            <Form.Group
              className="form-group-accounts form-group-icon"
              controlId="username-input"
            >
              <Form.Label>Username</Form.Label>
              <div className="input-icon">
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={user?.username || ''}
                  disabled
                />
                <Icon name="lock" />
              </div>
            </Form.Group>
          </div>
          <div className="content-section account-settings">
            <h2 className="section-title">Account Setting</h2>
            <AccountForm
              user={user}
              updateUser={updateUser}
              getUser={getUser}
            />
          </div>
          <div className="content-section change-password">
            <h2 className="section-title">Change Password</h2>
            <PasswordForm user={user} />
          </div>
          <div className="content-section delete-account">
            <h2 className="section-title">Delete your account</h2>
            <div className="section-text">
              <p>To delete your gfx account, you need to first:</p>
              <ul>
                <li>You must be the store owner</li>
                <li>Remove the associated users from your team</li>
                <li>Cancel your gfx subscription</li>
                <li>Need some help? Contact our team</li>
              </ul>
            </div>
          </div>
        </ContentMain>
      </ContentLayout>
    </div>
  );
};

export default AccountPage;
