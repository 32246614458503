import React from 'react';
import {ReactSVG as SVG} from 'react-svg';
import {IconProps, IconMap} from './IconMapping';
import './icon.scss';
import classNames from 'classnames';

const getFillColor = (props: IconProps) => {
  let fillColor = props.fillColor?.default;

  if (props.isHovering) {
    fillColor = props.fillColor?.hover;
  }

  if (props.disabled) {
    fillColor = props.fillColor?.disabled || '#ccc';
  }

  return fillColor;
};

const Icon: React.FC<IconProps> = (props) => {
  const tooltip = props.tooltip;
  const tooltipPosition = props.tooltipPosition || 'right';
  const getSvgElements = (svg: any) => {
    return [
      ...svg.getElementsByTagName('path'),
      ...svg.getElementsByTagName('rect'),
    ].filter((el) => el);
  };

  const [icon, setIcon] = React.useState<null | string>();

  React.useEffect(() => {
    const fileName = IconMap[props.name];
    const iconName = fileName.replace('.svg', '');

    if (!icon || !icon.includes(iconName)) {
      import(`../../assets/icons/${fileName}`)
        .then((module) => {
          setIcon(module.default);
        })
        .catch((error) => {
          console.error(`Icon ${props.name} not found`, error);
        });
    }
  }, [props.name]);

  const IconTooltip = () => {
    const classes = classNames('icon_tooltip', {
      [`icon_tooltip--${tooltipPosition}`]: !!tooltipPosition,
    });

    return (
      <div className={classes}>
        <p>{tooltip}</p>
      </div>
    );
  };

  if (!icon) {
    return null;
  }

  if (!props.action) {
    return (
      <>
        <SVG
          className={`icon_element icon_without_action ${props.classes || ''}`}
          src={icon!}
          beforeInjection={(svg) => {
            const fillColor = getFillColor(props);

            if (fillColor) {
              const elements = getSvgElements(svg);
              elements.forEach((element) => {
                if (!element.classList.contains('skip-svg-fill')) {
                  element.setAttribute('fill', fillColor);
                }
              });
            }

            if (props.width) {
              svg.setAttribute('width', `${props.width}`);
            }

            if (props.height) {
              svg.setAttribute('height', `${props.height}`);
            }
          }}
          onClick={props.onClick || (() => {})}
        />
        {tooltip && <IconTooltip />}
      </>
    );
  }

  return (
    <button
      onDoubleClick={(e) => e.preventDefault()}
      onClick={props.action}
      disabled={props.disabled}
      style={{
        backgroundColor: props.box ? props.boxHex : 'transparent',
      }}
      className={`icon_button ${props.classes || ''} ${
        props.box ? 'square_icon_background' : ''
      }`}
    >
      {tooltip && <IconTooltip />}
      <SVG
        className="icon_element"
        src={icon!}
        beforeInjection={(svg) => {
          const fillColor = getFillColor(props);

          if (fillColor) {
            const elements = getSvgElements(svg);
            elements.forEach((element) => {
              if (!element.classList.contains('skip-svg-fill')) {
                if (element.hasAttribute('stroke')) {
                  element.setAttribute('stroke', fillColor);
                } else {
                  element.setAttribute('fill', fillColor);
                }
              }
            });
          }
        }}
      />
    </button>
  );
};

export default Icon;
