import React from 'react';
import './PrinterCollapseContent.scss';
import FulfillmentServiceStatus from '../FulfillmentServiceStatus';

interface IFulfillmentCollapseContentProps {
  image?: string | React.ReactNode;
  id: string;
  loading: boolean;
  connected: boolean;
  errored: boolean;
  tested?: boolean;
  children: React.ReactNode;
  type?: 'printer' | 'webhook';
}

const FulfillmentCollapseContent: React.FC<
  IFulfillmentCollapseContentProps
> = ({
  image,
  id,
  loading,
  connected,
  errored,
  tested,
  children,
  type = 'printer',
}) => {
  return (
    <div className="PrinterCollapseContent">
      <div className="PrinterCollapseContent__image">
        {image &&
          (typeof image === 'string' ? (
            <img src={image} alt={`${id}-img-alt`} />
          ) : (
            image
          ))}
      </div>
      <div className="PrinterCollapseContent__form-container">{children}</div>
      <FulfillmentServiceStatus
        className="PrinterCollapseContent__status"
        type={type}
        loading={loading}
        errored={errored}
        connected={connected}
        tested={tested}
      />
    </div>
  );
};

export default FulfillmentCollapseContent;
