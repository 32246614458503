import React from 'react';
import {GFXFont} from '@best-apps/gfx-editor/lib/package/dist/types/v1Template';
import './FontsPicker.scss';
import ImageIcon from '../Icons/Icon';

const FontsPicker: React.FC<{
  fonts: GFXFont[];
  onClickFont: (font: GFXFont) => void;
  isSelectingFont: boolean;
}> = ({fonts, onClickFont, isSelectingFont}) => {
  const [filteredFonts, setFilteredFonts] = React.useState<GFXFont[]>([]);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [loadedFonts, setLoadedFonts] = React.useState<string[]>([]);

  React.useEffect(() => {
    setFilteredFonts(fonts);
  }, []);

  React.useEffect(() => {
    if (!searchInput) return setFilteredFonts(fonts);
    const filteredFonts = fonts.filter((font) => {
      return (
        font.fontFamily.toLowerCase().includes(searchInput.toLowerCase()) ||
        font.clientName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    setFilteredFonts(filteredFonts);
  }, [searchInput]);

  const loadFontFromUrl = async (fontName: string, fontUrl: string) => {
    if (loadedFonts.includes(fontName)) return;
    const fontFace = new FontFace(fontName, `url(${fontUrl})`);
    (document as any).fonts.add(fontFace);
    try {
      await fontFace.load();
      setLoadedFonts([...loadedFonts, fontName]);
    } catch (error) {
      console.error(`Error loading font ${fontName} from ${fontUrl}`, error);
    }
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const fontList = filteredFonts.map((font) => {
    loadFontFromUrl(font.fontFamily, font.urlFont);
    return (
      <div
        key={font.fontFamily}
        onClick={() => onClickFont(font)}
        className="font-picker-component__font"
      >
        <p style={{fontFamily: `"${font.fontFamily}"`}}>{font.fontFamily}</p>
      </div>
    );
  });

  if (!isSelectingFont) return null;
  return (
    <div className="font-picker-component">
      <div className="font-picker-component__search">
        <ImageIcon name="magnifierIcon" />
        <input
          onClick={(e) => e.stopPropagation()}
          onChange={handleSearchInput}
          type="text"
        />
      </div>
      <div className="font-picker-component__content">{fontList}</div>
    </div>
  );
};

export default FontsPicker;
