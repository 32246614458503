import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {selectShop} from '../../../features/shops/selectedShop';
import Icon from '../../../components/Icons/Icon';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-icon.svg';
import {
  getCollectionsAsync,
  resetCollectionsPages,
  resetCollections,
  getCollectionsByPage,
  selectTotalCollections,
  fetchStatusByPage,
} from '../../../features/collections/loadCollections';
import {resetCollectionDetails} from '../../../features/collections/loadCollectionsDetails';
import {resetCollectionsCountFetchStatus} from '../../../features/collections/loadCollectionsCount';
import {
  ICollectionsRequest,
  ELoadingStates,
  IImage,
  ICollectionResponse,
} from '@gfxco/contracts';
import PreviewSection from '../../../components/ImageDashboard/PreviewSection';
import CollectionBreadcrumb from '../../../components/ImageDashboard/CollectionBreadcrumb';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import CustomPagination from '../../../components/CustomPagination';
import CollectionsWizard from '../../../components/ImageDashboard/CollectionsWizard';

import './AllCollections.scss';
import ImageBanner from '../../../components/ImageBanner';

interface RequiredProps {
  handleOnDeleteCollection: (id: number) => void;
  handleOnDeleteImage: (id: number) => void;
}

interface OptionalProps {}

type AllCollectionsProps = RequiredProps & OptionalProps;

const AllCollections: React.FC<AllCollectionsProps> = (props) => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const preview = queryParams.preview || 'gallery';
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [showCollectionModal, setShowCollectionModal] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(true);
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const collections = useAppSelector((state) =>
    getCollectionsByPage(state, currentPage),
  );
  const totalCollections = useAppSelector((state) =>
    selectTotalCollections(state),
  );
  const collectionStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );
  const [collectionEditing, setCollectionEditing] =
    React.useState<ICollectionResponse>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOnClick = (name: string, id?: number) => {
    if (name && id)
      navigate(
        `/designs/collections/${encodeURIComponent(name)}?collectionId=${id}`,
      );
  };

  React.useEffect(() => {
    if (collectionStatus === ELoadingStates.IDLE) {
      getCollections();
    }
  }, [offset, limit, shopId, collectionStatus]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetCollectionsPages());
  }, [queryParams.sortBy, queryParams.search]);

  const getCollections = async () => {
    const params: ICollectionsRequest & {page: number} = {
      shopId,
      offset,
      limit,
      page: currentPage,
      order: sortBy === 'newest' ? 'desc' : 'asc',
    };

    if (search) params.name = search;
    dispatch(getCollectionsAsync(params));
  };

  const handleOnReset = () => {
    dispatch(resetCollectionDetails());
    dispatch(resetCollectionsPages());
    dispatch(resetCollectionsCountFetchStatus());
    dispatch(resetCollections());
    setCollectionEditing(undefined);
  };

  const handleOnCloseCollectionModal = () => {
    handleOnReset();
    setShowCollectionModal(false);
  };

  const handleOnEdit = (collection: IImage & {description?: string}) => {
    if (!collection.id) return;
    const collectionDraft: ICollectionResponse = {
      id: collection.id,
      name: collection.name,
      description: collection.description!,
      imageUrl: collection.imageUrl!,
      largeImageUrl: collection.largeImageUrl!,
    };
    setCollectionEditing(collectionDraft);
    setShowCollectionModal(true);
  };

  if (collectionStatus === ELoadingStates.FAILED) {
    return <div>Error loading collections!.</div>;
  }

  return (
    <div id="AllCollections">
      <div id="ViewCollection-Header">
        <CollectionBreadcrumb
          items={[
            {
              name: 'All collections',
              navigation: '/designs/collections',
              icon: <FolderIcon />,
            },
          ]}
        />
      </div>
      <ImageBanner show={showBanner} onClose={() => setShowBanner(false)} />
      {collectionStatus === ELoadingStates.LOADED && !collections.length ? (
        <div id="AllImages">
          <Card>
            <Card.Body className="empty-images">
              <Icon name="plusFolder" />
              <p>
                To create a collection, <span>upload your images.</span>
              </p>
              <Button
                onClick={() => {
                  setShowCollectionModal(true);
                }}
              >
                Get started!
              </Button>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <PreviewSection
          type="collections"
          onDeleteCollection={props.handleOnDeleteCollection}
          list={collections}
          handleOnClick={handleOnClick}
          isLoading={collectionStatus !== ELoadingStates.LOADED}
          listView={preview === 'list'}
          handleOnEdit={handleOnEdit}
        />
      )}
      <CollectionsWizard
        onDeleteImage={props.handleOnDeleteImage}
        open={showCollectionModal}
        onClose={handleOnCloseCollectionModal}
        collectionDraft={collectionEditing}
      />
      <CustomPagination
        limit={limit}
        currentPage={currentPage}
        total={totalCollections}
        handlePagination={handlePagination}
        entity="Collections"
      />
    </div>
  );
};

export default AllCollections;
