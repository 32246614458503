import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Skeleton from 'react-loading-skeleton';

import {
  ELoadingStates,
  IDesignTemplate,
  ISlotTemplateCanvas,
} from '@gfxco/contracts';

import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {selectShop} from '../../../features/shops/selectedShop';

import CustomPagination from '../../CustomPagination';
import ShopifyPublishModal from '../../ShopifyPublishModal';
import NoResultView from '../NoResultView';

import {
  getDesignTemplatesAsync,
  selectTotalDesignTemplates,
} from '../../../features/designTemplates/loadDesignTemplates';

import {
  getShopifyIntegrationStatusAsync,
  selectIntegrationStatus,
  selectIntegrationsFetchLoading,
} from '../../../features/integrations/loadShopifyIntegration';

import './DesignsList.scss';
import LoadingModal from '../../LoadingModal';
import DuplicateNewProduct from '../DuplicateNewProductModal/DuplicateNewProduct';
import DesignItem from '../DesignItem';

interface RequiredProps {
  designs: ISlotTemplateCanvas[];
}

interface OptionalProps {
  fetchStatus: ELoadingStates;
}

type DesignListProps = RequiredProps & OptionalProps;

const DesignsList: React.FC<DesignListProps> = ({designs, fetchStatus}) => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const [duplicatingTemplate, setDuplicatingTemplate] = React.useState(false);
  const [templateToCopy, setTemplateToCopy] =
    React.useState<ISlotTemplateCanvas | null>(null);
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [showShopifyPublishModal, setShowShopifyPublishModal] =
    React.useState(false);
  const [designSelected, setDesignSelected] = React.useState<
    IDesignTemplate | undefined
  >();

  const totalDesigns = useAppSelector(selectTotalDesignTemplates);

  const selectedShop = useAppSelector(selectShop);
  if (!selectedShop) return <></>;
  const shopId = selectedShop.id;

  const dispatch = useAppDispatch();

  const integrationConnected = useAppSelector((state) =>
    selectIntegrationStatus(state),
  );

  const integrationFetchStatus = useAppSelector((state) =>
    selectIntegrationsFetchLoading(state),
  );

  React.useEffect(() => {
    if (integrationFetchStatus === ELoadingStates.IDLE) {
      dispatch(getShopifyIntegrationStatusAsync(shopId));
    }
  }, [integrationFetchStatus, dispatch]);

  const lookForDesigns = () => {
    dispatch(
      getDesignTemplatesAsync({
        shopId: selectedShop.id,
        offset,
        page: currentPage,
        limit,
        sortBy,
        search,
      }),
    );
  };

  const handleOnPublish = (design: IDesignTemplate) => {
    setDesignSelected(design);
    setShowShopifyPublishModal(true);
  };

  const handleDuplicate = (design: IDesignTemplate) => {
    setTemplateToCopy(design);
  };

  const contentLoaded = ![ELoadingStates.IDLE, ELoadingStates.LOADING].includes(
    fetchStatus,
  );

  return (
    <div id="DesignsList">
      {designSelected && (
        <ShopifyPublishModal
          show={showShopifyPublishModal}
          onCloseModal={() => setShowShopifyPublishModal(false)}
          design={designSelected}
        />
      )}
      {fetchStatus === ELoadingStates.FAILED && (
        <Alert variant="danger">
          There was an error loading your design templates. Please reload the
          page or contact support.
        </Alert>
      )}
      {contentLoaded && designs.length > 0 && (
        <>
          <div className="design-list-container">
            {designs.map((design) => {
              return (
                <DesignItem
                  key={design.id}
                  design={design}
                  imageSrc={
                    design.startingSide === 'front'
                      ? design.proofFront + `?v=${Date.now()}`
                      : design.proofBack + `?v=${Date.now()}`
                  }
                  integrationConnected={integrationConnected}
                  onPublish={handleOnPublish}
                  onUpdateDesigns={lookForDesigns}
                  onDuplicate={handleDuplicate}
                />
              );
            })}
          </div>
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalDesigns}
            handlePagination={handlePagination}
            entity="Design Templates"
          />
          <LoadingModal
            loading={duplicatingTemplate}
            showSuccess={false}
            title="Duplicating template"
            description="Please wait while we duplicate the template."
            onClose={() => setDuplicatingTemplate(false)}
          />
          <DuplicateNewProduct
            template={templateToCopy}
            visible={!!templateToCopy}
            onClose={() => setTemplateToCopy(null)}
          />
        </>
      )}
      {contentLoaded && designs.length === 0 && <NoResultView />}
      {!contentLoaded && (
        <Skeleton
          className="design-item skeleton"
          containerClassName="design-list-container"
          count={limit}
          inline
        />
      )}
    </div>
  );
};

export default DesignsList;
