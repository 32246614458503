import React, {useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm, Controller} from 'react-hook-form';
import {ELoadingStates, ShopsResponse} from '@gfxco/contracts';
import {useNavigate} from 'react-router-dom';

// Components
import GFXColorSelect from '../GFXColorSelect/GFXColorSelect';
import {ReactComponent as MyStoreIcon} from '../../assets/icons/my-store-icon.svg';
import {ReactComponent as UsersIcon} from '../../assets/icons/user-menu-blue-icon.svg';
import GFXImageEditable from '../GFXImageEditable';

// Hooks
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectUpdatingStatus} from '../../features/shops/selectedShop';
import {updateShopAsync} from '../../features/shops/loadShops';

import uploadImage, {extractFileExtension} from '../../libs/uploadImage';
import {
  ColorOptions,
  IColorOption,
} from '../../pages/MyStorePage/storeColorOptions.data';

import './MyStoreForm.scss';

interface MyStoreFormProps {
  shop: ShopsResponse;
}

interface StoreFormValues {
  storeName: string;
  storeDescription: string;
  storeImage: File | string;
  storeColor: IColorOption;
}

export const MyStoreForm: React.FC<MyStoreFormProps> = ({shop}) => {
  const {register, handleSubmit, reset, control, formState} =
    useForm<StoreFormValues>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const updatingStatus = useAppSelector(selectUpdatingStatus);

  const getDefaultValueColor = () => {
    const value = ColorOptions.filter((color) => {
      return color.hex === shop.hex_color;
    });
    return value.length > 0 ? value[0] : ColorOptions[0];
  };

  const uploadStoreImage = async (file: File) => {
    const fileExtension = extractFileExtension(file.name);
    const newFileName = `shop-picture.${fileExtension}`;
    return uploadImage({
      file,
      fileName: newFileName,
      shopId: shop.id,
      omitImageReference: true,
    });
  };

  const handleFormSubmit = async (params: StoreFormValues) => {
    try {
      if (params.storeImage instanceof File) {
        const imageLoaded = await uploadStoreImage(params.storeImage as File);
        dispatch(
          updateShopAsync({
            shopId: shop.id,
            name: params.storeName,
            description: params.storeDescription,
            hex_color: params.storeColor.hex,
            shop_image_url: imageLoaded.imageUrl,
          }),
        );
        reset({
          storeColor: params.storeColor,
          storeDescription: params.storeDescription,
          storeName: params.storeName,
          storeImage: imageLoaded.imageUrl,
        });
      } else {
        dispatch(
          updateShopAsync({
            shopId: shop.id,
            name: params.storeName,
            description: params.storeDescription,
            hex_color: params.storeColor.hex,
          }),
        );
        reset({
          storeColor: params.storeColor,
          storeDescription: params.storeDescription,
          storeName: params.storeName,
          storeImage: params.storeImage,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Update form values when shop changes
    reset({
      storeName: shop.name,
      storeDescription: shop.description,
      storeColor: getDefaultValueColor(),
      storeImage: shop.shop_image_url,
    });
  }, [shop]);

  return (
    <div id="MyStoreForm" className="MyStoreForm-container">
      <Form
        className="MyStoreForm-container__form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <header>
          <MyStoreIcon width={19} height={19} />
          <h3>My gfx Store Preview</h3>
        </header>
        <div className="form-group-container">
          <Form.Group controlId="formStoreName">
            <Form.Label>Store Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="547650"
              autoComplete="off"
              defaultValue={shop.name}
              {...register('storeName', {
                required: true,
              })}
              maxLength={31}
            />
          </Form.Group>

          <Form.Group controlId="formStoreDescription">
            <Form.Label>Store Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="My store description"
              defaultValue={shop.description}
              {...register('storeDescription')}
              maxLength={31}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Store Color</Form.Label>
            <Controller
              name="storeColor"
              defaultValue={getDefaultValueColor()}
              control={control}
              render={({field}) => (
                <GFXColorSelect
                  options={ColorOptions}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Form.Group>

          <Form.Group controlId="formStoreGoToUserManagement">
            <Form.Label>Advanced Options</Form.Label>
            <Button
              variant="link"
              onClick={() => {
                navigate('/user-management');
              }}
              className="MyStoreForm-container__form__goToUserManagement"
            >
              <UsersIcon
                width={'1.375rem'}
                height={'1.14013rem'}
                style={{marginRight: '0.5rem'}}
              />{' '}
              Manage store users
            </Button>
          </Form.Group>
        </div>
        <div className="connect-container">
          <Button variant="primary" type="submit" disabled={!formState.isDirty}>
            {updatingStatus === ELoadingStates.LOADING
              ? 'Loading…'
              : 'Save Changes'}
          </Button>
        </div>
      </Form>

      <div className="MyStoreForm-container__status">
        <Controller
          name="storeImage"
          control={control}
          defaultValue={shop.shop_image_url}
          render={({field}) => {
            return (
              <GFXImageEditable
                src={field.value}
                onFileChange={(file) => {
                  field.onChange(file);
                }}
                onError={(error) => console.error(error)}
                addImageText="Add store image"
                limits={{
                  width: 3000,
                  height: 3000,
                  weight: 50,
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default MyStoreForm;
