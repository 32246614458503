import {
  IPatchUserPermissionsRequest,
  userPermissionsResponse,
} from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const getUserPermissions = async () => {
  try {
    const client = await loadClient();

    const response = await client.get<userPermissionsResponse>(
      `/user/permissions`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const patchUserPermissions = async (
  body: IPatchUserPermissionsRequest,
) => {
  try {
    const client = await loadClient();

    const bodyParams = {
      ...body,
    };

    const response = await client.patch(`/user/permissions/update`, bodyParams);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteUserFromShop = async (username: string) => {
  try {
    const client = await loadClient();
    const response = await client.delete(`/user/delete/${username}`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

function generatePassword() {
  const length = 12;
  const lowerCharset = 'abcdefghijklmnopqrstuvwxyz';
  const upperCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberCharset = '0123456789';
  const specialCharset = '!@#$%^&*()_+=-[]{}|;:,.<>?';
  const combinedCharset =
    lowerCharset + upperCharset + numberCharset + specialCharset;

  let password = '';
  password += lowerCharset.charAt(
    Math.floor(Math.random() * lowerCharset.length),
  );
  password += upperCharset.charAt(
    Math.floor(Math.random() * upperCharset.length),
  );
  password += numberCharset.charAt(
    Math.floor(Math.random() * numberCharset.length),
  );
  password += specialCharset.charAt(
    Math.floor(Math.random() * specialCharset.length),
  );

  for (let i = 4, n = combinedCharset.length; i < length; ++i) {
    password += combinedCharset.charAt(Math.floor(Math.random() * n));
  }

  // Shuffle password to ensure randomness
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return password;
}

export const inviteUserToShop = async (email: string) => {
  try {
    const randomPassword = generatePassword();

    const body = {
      email,
      password: randomPassword,
    };

    const client = await loadClient();
    const response = await client.post(`/user/invite`, body);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserEmail = async (newEmail: string) => {
  try {
    const body = {newEmail};

    const client = await loadClient();
    const response = await client.put(`/user/email`, body);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
