import React from 'react';

import Icon from '../../../components/Icons/Icon';
import useQueryParams from '../../../hooks/useQueryParams';
import {useNavigate} from 'react-router-dom';
import Modal from '../../../components/Modal/StepModal';
import SimpleModal from '../../../components/Modal/SimpleModal';

import './ConfirmationModal.scss';

const ConfirmationModal: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams();
  const [showConfirmation, setShowConfirmation] = React.useState(
    !!queryParams.confirmation,
  );
  const [showFailConfirmation, setShowFailConfirmation] = React.useState(
    !!queryParams.failed,
  );

  const handleOnClose = () => {
    setShowConfirmation(false);
    setShowFailConfirmation(false);
    delete queryParams.confirmation;
    delete queryParams.failed;
    setQueryParams({...queryParams});
  };

  const steps = [
    {
      name: '',
      component: (
        <div id="ConfirmationModal">
          <Icon name="congratulations" />

          <span className="confirmation-bold">Congratulations!</span>
          <span>Your account is now synced with gfx,</span>
          <div className="confirmation-text">
            but you need to finish the following steps to complete the setup.
          </div>
          <div>
            If you need help,{' '}
            <a
              href="https://gfx-help.notion.site/Help-Center-1ed49d73504a42f482a9a3257c635fe2"
              target="_blank"
              rel="noreferrer"
            >
              check out our documentation.
            </a>
          </div>
          <button onClick={handleOnClose} className="greeting-button">
            Got it!
          </button>
        </div>
      ),
    },
  ];

  return (
    <div id="Confirmation">
      <Modal
        isSaveEnabled={false}
        steps={steps}
        showSteps={false}
        selectedStepNumber={1}
        show={showConfirmation}
        showCloseButton={false}
        confirmationStyles
      />
      <SimpleModal
        className="modal-middle-page modal-styled"
        isSaveEnabled={false}
        modalTile={''}
        addCloseButton={false}
        show={showFailConfirmation}
      >
        <div id="ConfirmationModal">
          <Icon name="emptyFolder" />
          <span className="confirmation-bold">Oops!</span>
          <div className="greeting-text">
            Looks like we missed something, and we couldn&apos;t connect your
            store but don&apos;t worry, let&apos;s fix it!
          </div>
          {queryParams.store && (
            <button
              onClick={() => navigate(`/connect?store=${queryParams.store}`)}
              className="greeting-button"
            >
              Sync my store
            </button>
          )}
        </div>
      </SimpleModal>
    </div>
  );
};

export default ConfirmationModal;
