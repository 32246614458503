import React, {useState} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {PlusLg} from 'react-bootstrap-icons';
import {generateDesignEditLink} from '../../../api';
import {formatDate} from '../../../libs/formatUtils';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy-text-icon.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/user-icon.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/calendar-icon.svg';
import {IPrintInfo} from '@gfxco/contracts';

import './CardInfo.scss';

interface RequiredProps {
  id: number;
  createdAt: Date;
  printInfo: IPrintInfo;
  customerEmail: string | null;
  shopifyOrderId: string;
  templateId: number;
  designId: number;
  itemId: number;
  designNumber: string;
  customerName: string | null;
}

interface OptionalProps {
  generatedUrl?: string;
}
type OrdersModalProps = RequiredProps & OptionalProps;

const CardInfo: React.FC<OrdersModalProps> = ({
  id,
  createdAt,
  printInfo,
  customerEmail,
  shopifyOrderId,
  templateId,
  designId,
  itemId,
  generatedUrl,
  designNumber,
  customerName,
}) => {
  const [designLink, setDesignLink] = useState<string | undefined>(
    generatedUrl,
  );

  const reference = printInfo
    ? `${printInfo.printer} - ${printInfo.info.sku} x ${printInfo.info.color}`
    : '';

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  React.useEffect(() => {
    setDesignLink(generatedUrl);
  }, [generatedUrl]);

  const handleEditDesignLink = async () => {
    try {
      const editDesignLink = await generateDesignEditLink({
        designId: `${designId}`,
        orderItemId: `${itemId}`,
      });
      if (editDesignLink) setDesignLink(editDesignLink.generated_url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="CardInfo">
      <span className="card-title">Order information:</span>
      <div className="card-subtitle">Ecommerce order number:</div>
      <span className="item-value styled">{shopifyOrderId}</span>
      <span className="card-subtitle">gfx order number:</span>
      <span className="item-value styled">gfx#{id}</span>
      <span className="card-subtitle">Reference SKU:</span>
      <span className="item-value">{reference}</span>
      <span className="card-subtitle">Template ID:</span>
      <span className="item-value">{templateId}</span>
      <span className="card-subtitle">Design Number:</span>
      <InputGroup className="design-value">
        <Form.Control
          placeholder={designNumber}
          aria-label="design-number"
          disabled
        />
        <CopyIcon
          className="copy-icon"
          onClick={() => handleCopy(designNumber)}
        />
      </InputGroup>
      <hr />
      <span className="card-subtitle">Order placed:</span>
      <div className="item-value">
        <CalendarIcon /> <span>{formatDate(createdAt)}</span>
      </div>
      <span className="card-subtitle">Customer:</span>
      <div className="customer-info">
        <UserIcon />
        <span>{customerName || ''}</span>
      </div>
      <hr className="divider-info" />
      <span className="edit-link">Edit design link:</span>
      <InputGroup>
        <InputGroup.Text onClick={() => handleEditDesignLink()}>
          <PlusLg />
        </InputGroup.Text>
        <Form.Control
          placeholder={designLink || 'Generate new url'}
          aria-label="edit-link"
          disabled
        />
        {designLink && (
          <CopyIcon
            className="copy-icon"
            onClick={() => handleCopy(designLink)}
          />
        )}
      </InputGroup>
    </div>
  );
};

export default CardInfo;
