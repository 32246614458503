import React, {useEffect} from 'react';
import {useRouteError} from 'react-router-dom';

import {ReactComponent as OopsIcon} from '../../assets/icons/oops-icon.svg';

import './ErrorPage.scss';
import {Button} from 'react-bootstrap';

const {REACT_APP_HELP_CENTER_URL: HelpCenterURL = ''} = process.env;

interface OptionalProps {
  title?: string;
}

type ChipsProps = OptionalProps;

const ErrorPage: React.FC<ChipsProps> = ({title = 'Oops'}) => {
  const error = useRouteError();

  useEffect(() => {
    // We can do anything with this error catch, like send it to an external api
    console.error(error);
  }, []);

  return (
    <div id="error-page">
      <div className="error-page-row">
        <div id="error-page-column">
          <OopsIcon className="error-page-icon" />
        </div>

        <div id="error-page-column">
          <div>
            <h1 className="error-page-title">{title}</h1>
            <p className="error-page-message">
              Something went wrong with this page
              <br />
              need help?{' '}
              <a href={HelpCenterURL} className="error-page-link">
                Learn more about gfx
              </a>{' '}
              or
            </p>

            <Button variant="primary" href="/">
              <span className="error-page-button-message">Go Back Home</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
