import React, {FormEvent} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Contracts from '@gfxco/contracts';
import {
  verifyUserAttribute,
  verifyUserAttributeSubmit,
} from '../../../features/auth';
import {useAppDispatch} from '../../../app/hooks';
import * as userApi from '../../../api/user';

import './ConfirmEmailForm.scss';
import Icon from '../../../components/Icons/Icon';

interface ConfirmEmailFormRequiredProps {
  user: Contracts.ICognitoUser;
  onConfirmEmail: () => void;
  onTryAgain: () => void;
  email?: string;
}

const ConfirmEmailForm: React.FunctionComponent<
  ConfirmEmailFormRequiredProps
> = ({user, onConfirmEmail, onTryAgain}) => {
  const [verifyCodeStatus, setVerifyCodeStatus] = React.useState(
    Contracts.ELoadingStates.IDLE,
  );
  const [resendCodeStatus, setResendCodeStatus] = React.useState(
    Contracts.ELoadingStates.IDLE,
  );
  const [formError, setFormError] = React.useState('');
  const isVerifyCodeLoading =
    verifyCodeStatus === Contracts.ELoadingStates.LOADING;
  const dispatch = useAppDispatch();

  const confirmEmail = async (event: FormEvent) => {
    try {
      setVerifyCodeStatus(Contracts.ELoadingStates.LOADING);
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLFormElement;
      const code = target.code.value;

      await dispatch(
        verifyUserAttributeSubmit({user, attributeName: 'email', code}),
      ).unwrap();

      // update in database
      await userApi.updateUserEmail(user?.attributes?.email!);

      setVerifyCodeStatus(Contracts.ELoadingStates.LOADED);
      onConfirmEmail();
    } catch (error: any) {
      setVerifyCodeStatus(Contracts.ELoadingStates.FAILED);
      setFormError(error.message);
    }
  };

  const reSendCode = async () => {
    if (resendCodeStatus === Contracts.ELoadingStates.LOADED) {
      return;
    }
    setResendCodeStatus(Contracts.ELoadingStates.LOADING);
    try {
      await dispatch(
        verifyUserAttribute({user, attributeName: 'email'}),
      ).unwrap();
      setResendCodeStatus(Contracts.ELoadingStates.LOADED);
    } catch (error: any) {
      setResendCodeStatus(Contracts.ELoadingStates.FAILED);
      setFormError(error.message);
      console.log('Error confirming email', error);
    }
  };

  return (
    <Form
      id="ConfirmEmailForm"
      className="account-page-form"
      noValidate
      onSubmit={confirmEmail}
    >
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="email-input"
      >
        <Form.Label>Email*</Form.Label>
        <div className="input-icon">
          <Form.Control
            type="email"
            placeholder="Email"
            value={user?.attributes?.email}
            disabled
          />
          <Icon name="pencil" />
        </div>
      </Form.Group>
      <Form.Group controlId="code">
        <Form.Label>
          Confirm email code <span className="required-symbol">*</span>
        </Form.Label>
        <Form.Control type="number" name="code" required />
        <span className="input-info">Code will expire in 24h</span>
        <span className="input-info">
          You didn&apos;t receive the code or the email you entered is wrong?
          Please,{' '}
          <a
            className="link-without-href"
            onClick={(event) => {
              event.preventDefault();
              onTryAgain();
            }}
          >
            update your address again
          </a>
        </span>
      </Form.Group>

      {formError && <span className="form-feedback-error">{formError}</span>}

      <div className="form-buttons">
        <Button
          variant="link"
          onClick={reSendCode}
          disabled={resendCodeStatus === Contracts.ELoadingStates.LOADED}
        >
          {resendCodeStatus === Contracts.ELoadingStates.LOADED
            ? 'Code sent'
            : 'Re-send Code'}
        </Button>
        <Button variant="primary" type="submit" disabled={isVerifyCodeLoading}>
          {isVerifyCodeLoading ? 'Verifying...' : 'Verify Code'}
        </Button>
      </div>
    </Form>
  );
};

export default ConfirmEmailForm;
